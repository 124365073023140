<template>
  <div class="contents servicePage" >
    <div class="topVisual">
      <div class="container">
        <h4>서비스</h4>
        <h5>메가존클라우드의 매니지드 서비스와 함께 공공 및 민간에 더욱 특화된 kt cloud를 경험하세요.</h5>
      </div>
      <div class="location">
        <div class="container">
          <i class="fa-solid fa-house" @click="goMain()"></i><i class="fa-solid fa-chevron-right"></i>
          <a href="../service"><span class="txt">서비스</span></a>
        </div>
      </div>
    </div>

    <section class="whyWrap" id="why" style="display: none">
      <div class="container">
        <div class="why">
          <span class="txt01">Why kt cloud on MEGAZONECLOUD?</span>
          <span class="txt02">메가존클라우드, <br>성공적인 kt cloud 도입을 위한 필수 선택</span>
          <span class="txt03">메가존클라우드는 국내 및 아시아 업계 1위 MSP 사업자이자 <br>kt cloud 플래티넘 파트너사로서 고객사에 최적의 서비스와 <br>솔루션을 제공합니다.</span>
        </div>
      </div>
    </section>

    <div class="serviceList" id="serviceList">
      <span class="tabLine"></span>
      <div class="container">

        <v-tabs center-active v-model="tabItem">
          <v-tab href="#publicCloud"><a>공공 클라우드</a></v-tab>
          <v-tab href="#cloud">민간 클라우드</v-tab>
          <!-- <v-tab>마틸다 AI 플랫폼</v-tab> -->
          <!-- <v-tab href="#dr">DR as a Service</v-tab> -->
        </v-tabs>

        <v-tabs-items :value="tabItem" >

          <v-tab-item class="itemDetail itemDetail01" value="publicCloud" id="publicCloud">
            <div class="whyWrap" id="why">
              <div class="why">
                <span class="txt01">Why kt cloud on MEGAZONECLOUD?</span>
                <span class="txt02">메가존클라우드, <br>성공적인 kt cloud 도입을 위한 필수 선택</span>
                <span class="txt03">메가존클라우드는 국내 및 아시아 1위 MSP 사업자이자 <br>kt cloud 플래티넘 파트너사로서 고객사에 최적의 서비스와 <br>솔루션을 제공합니다.</span>
              </div>
            </div>
            <div class="topWrap">
              <div class="top01">
                <span class="topImg"></span>
                <h5>보안에 특화된 공공기관 전용 클라우드 서비스</h5>
                <h3>공공 클라우드</h3>
                <h4>행정·공공기관 및 공공기업의 정보시스템에 더 안전하고 손쉽게 클라우드를 도입하실 수 있습니다.</h4>
                <a href="../contact/#contactEmail" class="seviceBtn">상담신청 바로가기 <i class="fa-solid fa-chevron-right"></i></a>
              </div>
              <div class="top02">
                <span class="txt">공공 클라우드 분야 No. 1 공공기관 전용 클라우드 컴퓨팅 서비스 보안인증인 CSAP 등 여러 국내외 보안인증을 보유하여 안심하고 이용 가능합니다.</span>
              </div>
            </div>
            <div class="conWrap">
              <div class="con01">
                <h3>kt cloud의 공공 클라우드</h3>
                <h5>정부 규정 및 지침에 맞추어 구축한 공공기관 전용 클라우드로 <br>공공 서비스에 최적화된 맞춤형 클라우드 서비스를 제공합니다.</h5>
                <div class="imgWrap">
                  <ul>
                    <li>
                      <span class="txt01">G1 플랫폼</span>
                      <span class="txt02">
                        <ul>
                          <li>· 클라우드스택 기반의 클라우드 플랫폼</li>
                          <li>· 퍼블릭/프라이빗 망분리 환경 제공</li>
                          <li>· 사설 IP를 통한 내부 통신용 네트워크 제공</li>
                          <li>· VPN(가상사설망)을 통한 사설 통신 제공</li>
                        </ul>
                      </span>
                    </li>
                    <li class="newBox">
                      <span class="newTag">NEW!</span>
                      <span class="txt01">D1 플랫폼</span>
                      <span class="txt02">
                        <ul>
                          <li>· 오픈소스 기반의 강력한 확정성을 가진 클라우드 플랫폼</li>
                          <li>· Tier 구조의 망분리 환경 제공(퍼블릭/프라이빗 Tier 기본제공)</li>
                          <li>· 하나의 계정에 최대 15개 Tier 생성 가능</li>
                          <li>· 고성능 CPU 서버 이용 가능 (최대 64vCore, 256GB)</li>
                          <li>· Data Lake, AI 플랫폼 서비스 이용 가능</li>
                          <li class="txt03">* 신규 클라우드 플랫폼으로 최신 사양의 서버와 가상화솔루션을 적용하여 디스크 용량, 네트워크 속도 등이 개선되었습니다.</li>
                        </ul>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="con02">
                <article class="iconCon">
                  <h3>보안에 특화된 안정적인 <br>클라우드 인프라를 제공</h3>
                  <h5>물리적으로 분리된 공공 클라우드 서비스로 IPS, 방화벽, 24시간 보안관제 서비스를 제공합니다.</h5>
                  <ul>
                    <li><span class="icon icon01"></span><span class="txt">물리적으로 분리 구성된<br>공공전용 시스템 구성</span></li>
                    <li><span class="icon icon02"></span><span class="txt">고객사별<br>네트워크 분리</span></li>
                    <li><span class="icon icon03"></span><span class="txt">CC인증을 획득한 <br>보안장비로 보안 강화</span></li>
                    <li><span class="icon icon04"></span><span class="txt">CSAP 인증을 비롯한<br>다양한 보안인증 획득</span></li>
                  </ul>
                </article>
                <article class="txtCon txtConG">
                  <h3>G-Cloud 보안장비</h3>
                  <ul>
                    <li>
                      <span class="icon01"></span>
                      <span class="txt01">IPS<span class="txt01_2">(Intrusion Prevention System)</span></span>
                      <span class="txt02">
                        <p>시스템 및 네트워크 자원에 대한 다양한 형태의 침입 행위(해킹, DDoS, 웹/바이러스 등) 탐지, 분석, 차단</p>
                      </span>
                    </li>
                    <li>
                      <span class="icon02"></span>
                      <span class="txt01">방화벽<span class="txt01_2">(Firewall)</span></span>
                      <span class="txt02">
                        <p>고객 전담 담당자 지원</p>
                        <p>동일한 아키텍처로 완벽한 자원 및 운영 통합 지원 </p>
                      </span>
                    </li>
                    <li>
                      <span class="icon03"></span>
                      <span class="txt01">웹 방화벽<span class="txt01_2">(Web Application Firewall)</span></span>
                      <span class="txt02">
                        <p>클라우드 상에서 실행 중인 웹 서비스에 대한 사이트 공격을 탐지 및 차단</p>
                        <p>웹 서버 앞단에 위치시켜 외부로부터 들어오는 HTTP/HTTPS 프로토콜 트래픽 감시</p>
                      </span>
                    </li>
                    <li>
                      <span class="icon04"></span>
                      <span class="txt01">3rd Party Soultion</span>
                      <span class="txt02">
                        <p>시스템 접근제어, DB 접근제어/암호화, 백신 등 다양한 종류의 보안솔루션을 Marketplace를 통해 제공</p>
                      </span>
                    </li>
                  </ul>
                </article>

                <article class="txtCon txtConCsap">
                  <h3>다수의 보안인증을 획득한 <br>최고의 인프라 안정성 확보</h3>
                  <h5>공공기관의 높은 보안 요건 수준을 완벽히 충족한 공공 클라우드 서비스를 제공합니다.</h5>
                  <div class="csapWrap">
                    <span class="img01"></span>
                    <span class="txt">국내 보안 및 클라우드 인증 현황 </span>
                  </div>
                  <div class="csapWrap">
                    <span class="img02"></span>
                    <span class="txt">ISO(국제표준화기구) 인증 현황</span>
                  </div>
                  <span class="csapTxt">
                    <h5>* CSAP(클라우드 보안인증제, Cloud Security Assurance Program)</h5>
                    <h6>공공기관에 클라우드 서비스를 공급하기 위해 반드시 획득해야 하는 인증입니다. <br>kt cloud는 국내 최초로 CSAP인증을 획득하며 ‘클라우드 구조’ 및 ‘제공 서비스’ 관련 공공 클라우드 표준모델로 선정되었습니다.</h6>  
                  </span>
                </article>

                <article class="txtCon txtConList">
                  <h3>고객 맞춤형 클라우드 상품 조합으로 <br>최적의 클라우드 인프라 구축 및 운영  </h3>
                  <h5>kt cloud 전용 회선으로 프라이빗하게 연결 가능하며, 공공 분야에 최적화된 상품을 제공합니다.</h5>
                  <span class="img1"></span>
                  <ul>
                    <li>
                      <span class="txt01">Computing, Storage</span>
                      <span class="txt02">
                        <ul>
                          <li>Server</li>
                          <li>SSD Server</li>
                          <li>GPU Server</li>
                          <li>CloudFarm</li>
                          <li>Object Storage</li>
                          <li>NAS</li>
                          <li class="dot">.</li>
                          <li class="dot">.</li>
                          <li class="dot">.</li>
                        </ul>
                      </span>
                    </li>
                    <li>
                      <span class="txt01">Network, Security</span>
                      <span class="txt02">
                        <ul>
                          <li>CDN</li>
                          <li>Load Balancer</li>
                          <li>GSLB</li>
                          <li>Connect Hub</li>
                          <li>WAF Pro</li>
                          <li>kt cloud 클린존</li>
                          <li class="dot">.</li>
                          <li class="dot">.</li>
                          <li class="dot">.</li>
                        </ul>
                      </span>
                    </li>
                    <li>
                      <span class="txt01">Management</span>
                      <span class="txt02">
                        <ul>
                          <li>DR</li>
                          <li>Import/Export</li>
                          <li>Auto Scaling</li>
                          <li>Watch</li>
                          <li>Backup</li>
                          <li>Managed</li>
                          <li class="dot">.</li>
                          <li class="dot">.</li>
                          <li class="dot">.</li>
                        </ul>
                      </span>
                    </li>
                    <li>
                      <span class="txt01">Etc.</span>
                      <span class="txt02">
                        <ul>
                          <li>KT PaaS-TA</li>
                          <li>WebtoB</li>
                          <li>DBaaS for Redis</li>
                          <li>Data Lake</li>
                          <li class="dot">.</li>
                          <li class="dot">.</li>
                          <li class="dot">.</li>
                        </ul>
                      </span>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
          </v-tab-item>

          <v-tab-item class="itemDetail itemDetail02" value="cloud">
            <div class="whyWrap" id="why">
              <div class="why">
                <span class="txt01">Why kt cloud on MEGAZONECLOUD?</span>
                <span class="txt02">메가존클라우드, <br>성공적인 kt cloud 도입을 위한 필수 선택</span>
                <span class="txt03">메가존클라우드는 국내 및 아시아 1위 MSP 사업자이자 <br>kt cloud 플래티넘 파트너사로서 고객사에 최적의 서비스와 <br>솔루션을 제공합니다.</span>
              </div>
            </div>
            <div class="topWrap">
              <div class="top01">
                <span class="topImg"></span>
                <h5>산업별 맞춤화한 클라우드 통합 관리 서비스   </h5>
                <h3>민간 클라우드</h3>
                <h4>기업의 탄력적인 수요 증감에 즉각 대응할 수 있도록 고안된 최적의 기업 맞춤형 클라우드입니다.</h4>
                <a href="../contact/#contactEmail" class="seviceBtn">상담신청 바로가기 <i class="fa-solid fa-chevron-right"></i></a>
              </div>
              <div class="top02">
                <span class="txt">kt cloud는 고객이 다양한 서비스를 구축할 수 있도록 가용성, 안정성, 유연성, 확장성을 모두 갖춘 우수한 클라우드 서비스를 제공합니다.</span>
              </div>
            </div>

            <div class="conWrap">
              <div class="con01">
                <h3>고객의 비즈니스 요구사항에 <br>최적화된 민간 클라우드</h3>
                <h5>메가존클라우드는 kt cloud의 데이터센터와 네트워크를 활용한 클라우드 매니지드 서비스를 제공하며 <br>고객사 니즈에 맞추어 클라우드 설계와 운영, 관리 서비스를 통해 비즈니스 성장을 지원합니다.</h5>
                <span class="sevice_cloud_img01"></span>
              </div>

              <div class="con02">
                <article class="txtCon iconCon">
                  <h3>메가존클라우드｜kt cloud <br>최적의 통합 관리 서비스</h3>
                  <h5>메가존클라우드는 5,000여 고객사의 클라우드 전환 경험을 통해 다양한 산업에 대한 이해와 지식을 <br>바탕으로 고객사 니즈에 맞는 클라우드 통합 관리 서비스를 제공합니다.</h5>
                  <ul>
                    <li>
                      <span class="txt01">국내 최고의 <br>기업전용 서포트 서비스</span>
                      <span class="txt02">
                        <p>기업의 IT 환경에 최적화된 컨설팅, <br>설계, 구축 및 운영 서비스 제공</p>
                      </span>
                    </li>
                    <li>
                      <span class="txt01">고객 맞춤형 <br>클라우드 지원 서비스</span>
                      <span class="txt02">
                        <p>데이터센터 구축 및 운영 노하우를 기반으로 <br>End-to-End 서비스 제공</p>
                      </span>
                    </li>
                    <li>
                      <span class="txt01">물리적인 H/W보안과 <br>보안환경 기술지원 제공</span>
                      <span class="txt02">
                        <p>고객의 정보자산을 안전하게 보호하고 <br>관리하기 위한 서비스 제공</p>
                      </span>
                    </li>
                  </ul>
                </article>


                <article class="txtCon txtConCsap">
                  <h3>고객 비즈니스별 <br>특화된 보안 서비스 제공</h3>
                  <h5>kt cloud 전용 회선으로 프라이빗하게 연결하며, 고객별 최적화된 클라우드 상품을 제안합니다.</h5>
                  <span class="sevice_cloud_img02"></span>
                </article>

                <article class="txtCon txtConList">
                  <h3>클라우드 기반의 <br>다양한 솔루션과 S/W를 제공</h3>
                  <h5>kt cloud 전용 회선으로 프라이빗하게 연결 가능하며, 기업 분야에 최적화된 상품을 제공합니다.</h5>
                  <ul>
                    <li>
                      <span class="txt01">Computing, Storage</span>
                      <span class="txt02">
                        <ul>
                          <li>Server</li>
                          <li>SSD Server</li>
                          <li>GPU Server</li>
                          <li>CloudFarm</li>
                          <li>Object Storage</li>
                          <li>NAS</li>
                          <li class="dot">.</li>
                          <li class="dot">.</li>
                          <li class="dot">.</li>
                        </ul>
                      </span>
                    </li>
                    <li>
                      <span class="txt01">Network, Security</span>
                      <span class="txt02">
                        <ul>
                          <li>CDN</li>
                          <li>Load Balancer</li>
                          <li>GSLB</li>
                          <li>Connect Hub</li>
                          <li>WAF Pro</li>
                          <li>kt cloud 클린존</li>
                          <li class="dot">.</li>
                          <li class="dot">.</li>
                          <li class="dot">.</li>
                        </ul>
                      </span>
                    </li>
                    <li>
                      <span class="txt01">Management</span>
                      <span class="txt02">
                        <ul>
                          <li>DR</li>
                          <li>Import/Export</li>
                          <li>Auto Scaling</li>
                          <li>Watch</li>
                          <li>Backup</li>
                          <li>Managed</li>
                          <li class="dot">.</li>
                          <li class="dot">.</li>
                          <li class="dot">.</li>
                        </ul>
                      </span>
                    </li>
                    <li>
                      <span class="txt01">Etc.</span>
                      <span class="txt02">
                        <ul>
                          <li>KT PaaS-TA</li>
                          <li>WebtoB</li>
                          <li>DBaaS for Redis</li>
                          <li>Data Lake</li>
                          <li class="dot">.</li>
                          <li class="dot">.</li>
                          <li class="dot">.</li>
                        </ul>
                      </span>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
          </v-tab-item>
          
          <v-tab-item class="itemDetail itemDetail03" value="dr" style="display: none;">
            <div class="whyWrap" id="why">
              <div class="why">
                <span class="txt01">Why kt cloud on MEGAZONECLOUD?</span>
                <span class="txt02">메가존클라우드, <br>성공적인 kt cloud 도입을 위한 필수 선택</span>
                <span class="txt03">메가존클라우드는 국내 및 아시아 업계 1위 MSP 사업자이자 <br>kt cloud 플래티넘 파트너사로서 고객사에 최적의 서비스와 <br>솔루션을 제공합니다.</span>
              </div>
            </div>
            <div class="topWrap">
              <div class="top01">
                <span class="topImg"></span>
                <h5>국내 유일의 클라우드 기반 무중단 DR 솔루션</h5>
                <h3>KT DR as a Service</h3>
                <h4>KT DR as a Service는 클라우드 기반 높은 확장성과 비용 효율적인 주문형(On-Demand) 재해 복구서비스 입니다.</h4>
                <a href="../contact/#contactEmail" class="seviceBtn">상담신청 바로가기 <i class="fa-solid fa-chevron-right"></i></a>
              </div>
              <div class="top02">
                <span class="txt">2023년 DR 구축을 계획하고 계신가요? <br>그 시작의 순간을 <span class="txt_b">kt cloud</span>가 함께 하겠습니다.</span>
              </div>
            </div>

            <div class="conWrap">
              <div class="con01">
                <h3>KT DR as a Service</h3>
                <h5>하이브리드 클라우드 기반의 컴퓨팅 및 가상 네트워크 환경에서 비즈니스 중단없이 <br>기존 프라이빗 클라우드에서 퍼블릭 클라우드로의 DR 환경 구축이 가능합니다.</h5>
                <div class="imgWrap">
                  <ul>
                    <li>
                      <span class="sevice_dr_img01"></span>
                      <span class="txt01">엔터프라이즈 & 스타트업 기업고객</span>
                      <span class="txt02">프라이빗 클라우드 환경</span>
                    </li>
                    <li>
                      <span class="sevice_dr_img02"></span>
                      <span class="txt01">KT DR as a Service</span>
                      <span class="txt02">퍼블릭 클라우드 환경</span>
                    </li>
                    <li class="sevice_dr_iconWrap">
                      <span class="sevice_dr_img03"></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="con02">
                <article class="iconCon">
                  <h3>kt cloud의 높은 호환성. 안정성. 보안성 제공</h3>
                  <h5>프라이빗 클라우드 환경에서 가장 효율적이고 안전한 DR 구축이 가능합니다.</h5>
                  <ul>
                    <li><span class="icon icon01"></span><span class="txt">중앙 집중화된<br>복구 계획</span></li>
                    <li><span class="icon icon02"></span><span class="txt">VMware 제품<br>스택과 통합</span></li>
                    <li><span class="icon icon03"></span><span class="txt">자동화된 DR<br>워크 플로우</span></li>
                    <li><span class="icon icon04"></span><span class="txt">하이브리드 DR<br>구축 간소화</span></li>
                  </ul>
                </article>
                <article class="txtCon">
                  <h3>도입이점</h3>
                  <h5>DR 구축을 위한 인프라 투자 비용 없이 kt cloud로 탄력적인 DR 환경을 구축하세요.</h5>
                  <ul>
                    <li>
                      <span class="txt01">기존 DR 환경 구축에 비해 낮은 TCO</span>
                      <span class="txt02">
                        <p>공간 및 비용 부담 감소</p>
                        <p>하드웨어 및 시설에 대한 자본 지출 비용 감소</p>
                      </span>
                    </li>
                    <li>
                      <span class="txt01">국내 최고의 운영관리 서비스</span>
                      <span class="txt02">
                        <p>고객 전담 담당자 지원</p>
                        <p>동일한 아키텍처로 완벽한 자원 및 운영 통합 지원 </p>
                      </span>
                    </li>
                    <li>
                      <span class="txt01">에코시스템 솔루션 제공 서비스 </span>
                      <span class="txt02">
                        <p>kt cloud의 백업 및 하이브리드 클라우드 DR 구축을 위한 통합 서비스 제공</p>
                      </span>
                    </li>
                  </ul>
                </article>
                <article class="txtCon">
                  <h3>도입효과</h3>
                  <h5>클라우드 VM에 확장 가능하고 비용 효율적인 재해복구 서비스로 무중단 시스템 구축이 가능합니다.</h5>
                  <ul>
                    <li>
                      <span class="txt01">클라우드 비즈니스 연속성 확보</span>
                      <span class="txt02">
                        <p>즉각적인 마운트 및 복구</p>
                        <p>클라우드 컴퓨팅 인프라 및 서비스 제공</p>
                      </span>
                    </li>
                    <li>
                      <span class="txt01">강력한 호환성과 안정성, 보안성 확보</span>
                      <span class="txt02">
                        <p>클라우드 VM에 즉시 확장가능 및 효율성 증대</p>
                        <p>수천 개의 VM에 대한 중앙 집중식 복구 가능 </p>
                      </span>
                    </li>
                    <li>
                      <span class="txt01">운영비 절감</span>
                      <span class="txt02">
                        <p>대규모의 초기 투자 없이 비용 효율적인 운영이 가능</p>
                        <p>월별 사용비용으로 인프라를 관리 가능 </p>
                      </span>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
          </v-tab-item>

          <v-tab-item class="itemDetail itemDetail03" value="dr" style="display: none;">
            <div class="whyWrap" id="why">
              <div class="why">
                <span class="txt01">Why kt cloud on MEGAZONECLOUD?</span>
                <span class="txt02">메가존클라우드, <br>성공적인 kt cloud 도입을 위한 필수 선택</span>
                <span class="txt03">메가존클라우드는 국내 및 아시아 업계 1위 MSP 사업자이자 <br>kt cloud 플래티넘 파트너사로서 고객사에 최적의 서비스와 <br>솔루션을 제공합니다.</span>
              </div>
            </div>
            <div class="topWrap">
              <div class="top01">
                <span class="topImg"></span>
                <h5>국내 유일의 클라우드 기반 무중단 DR 솔루션</h5>
                <h3>KT DR as a Service</h3>
                <h4>KT DR as a Service는 클라우드 기반 높은 확장성과 비용 효율적인 주문형(On-Demand) 재해 복구서비스 입니다.</h4>
                <a href="../contact/#contactEmail" class="seviceBtn">상담신청 바로가기 <i class="fa-solid fa-chevron-right"></i></a>
              </div>
              <div class="top02">
                <span class="txt">2023년 DR 구축을 계획하고 계신가요? <br>그 시작의 순간을 <span class="txt_b">kt cloud</span>가 함께 하겠습니다.</span>
              </div>
            </div>

            <div class="conWrap">
              <div class="con01">
                <h3>KT DR as a Service</h3>
                <h5>하이브리드 클라우드 기반의 컴퓨팅 및 가상 네트워크 환경에서 비즈니스 중단없이 <br>기존 프라이빗 클라우드에서 퍼블릭 클라우드로의 DR 환경 구축이 가능합니다.</h5>
                <div class="imgWrap">
                  <ul>
                    <li>
                      <span class="sevice_dr_img01"></span>
                      <span class="txt01">엔터프라이즈 & 스타트업 기업고객</span>
                      <span class="txt02">프라이빗 클라우드 환경</span>
                    </li>
                    <li>
                      <span class="sevice_dr_img02"></span>
                      <span class="txt01">KT DR as a Service</span>
                      <span class="txt02">퍼블릭 클라우드 환경</span>
                    </li>
                    <li class="sevice_dr_iconWrap">
                      <span class="sevice_dr_img03"></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="con02">
                <article class="iconCon">
                  <h3>kt cloud의 높은 호환성. 안정성. 보안성 제공</h3>
                  <h5>프라이빗 클라우드 환경에서 가장 효율적이고 안전한 DR 구축이 가능합니다.</h5>
                  <ul>
                    <li><span class="icon icon01"></span><span class="txt">중앙 집중화된<br>복구 계획</span></li>
                    <li><span class="icon icon02"></span><span class="txt">VMware 제품<br>스택과 통합</span></li>
                    <li><span class="icon icon03"></span><span class="txt">자동화된 DR<br>워크 플로우</span></li>
                    <li><span class="icon icon04"></span><span class="txt">하이브리드 DR<br>구축 간소화</span></li>
                  </ul>
                </article>
                <article class="txtCon">
                  <h3>도입이점</h3>
                  <h5>DR 구축을 위한 인프라 투자 비용 없이 kt cloud로 탄력적인 DR 환경을 구축하세요.</h5>
                  <ul>
                    <li>
                      <span class="txt01">기존 DR 환경 구축에 비해 낮은 TCO</span>
                      <span class="txt02">
                        <p>공간 및 비용 부담 감소</p>
                        <p>하드웨어 및 시설에 대한 자본 지출 비용 감소</p>
                      </span>
                    </li>
                    <li>
                      <span class="txt01">국내 최고의 운영관리 서비스</span>
                      <span class="txt02">
                        <p>고객 전담 담당자 지원</p>
                        <p>동일한 아키텍처로 완벽한 자원 및 운영 통합 지원 </p>
                      </span>
                    </li>
                    <li>
                      <span class="txt01">에코시스템 솔루션 제공 서비스 </span>
                      <span class="txt02">
                        <p>kt cloud의 백업 및 하이브리드 클라우드 DR 구축을 위한 통합 서비스 제공</p>
                      </span>
                    </li>
                  </ul>
                </article>
                <article class="txtCon">
                  <h3>도입효과</h3>
                  <h5>클라우드 VM에 확장 가능하고 비용 효율적인 재해복구 서비스로 무중단 시스템 구축이 가능합니다.</h5>
                  <ul>
                    <li>
                      <span class="txt01">클라우드 비즈니스 연속성 확보</span>
                      <span class="txt02">
                        <p>즉각적인 마운트 및 복구</p>
                        <p>클라우드 컴퓨팅 인프라 및 서비스 제공</p>
                      </span>
                    </li>
                    <li>
                      <span class="txt01">강력한 호환성과 안정성, 보안성 확보</span>
                      <span class="txt02">
                        <p>클라우드 VM에 즉시 확장가능 및 효율성 증대</p>
                        <p>수천 개의 VM에 대한 중앙 집중식 복구 가능 </p>
                      </span>
                    </li>
                    <li>
                      <span class="txt01">운영비 절감</span>
                      <span class="txt02">
                        <p>대규모의 초기 투자 없이 비용 효율적인 운영이 가능</p>
                        <p>월별 사용비용으로 인프라를 관리 가능 </p>
                      </span>
                    </li>
                  </ul>
                </article>
              </div>
            </div>
          </v-tab-item>

        </v-tabs-items>
      </div>
    </div>

    <div class="indicator">
      <div class="list">
        <router-link to="/service?tabItem=cloud" :class="{active: tabItem=='cloud'}">민간 클라우드</router-link>
        <router-link to="/service?tabItem=publicCloud" :class="{active: tabItem=='publicCloud'}">공공 클라우드</router-link>
        <router-link to="/service?tabItem=dr" :class="{active: tabItem=='dr'}">DR as a Service</router-link>
        <a href="../contact/#contactEmail" class="ask"><i class="fa-solid fa-comment-dots"></i>서비스 문의</a>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  methods: {
    goMain(){
      this.$router.push({name: 'MainPage'})
    },
  },
  data: function() {
    return {
      // tabItem: null
    }
  },
  computed: {
    tabItem: {
      set (tabItem) {
        this.$router.replace({ query: { ...this.$route.query, tabItem } })
      },
      get () {
        return this.$route.query.tabItem
      }
    }
  }
}
</script>