<template>
  <div class="contents mediaPage reportPage">
    <div class="topVisual">
      <div class="container">
        <h4>미디어</h4>
        <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
      </div>
      <div class="location">
        <div class="container">
          <i class="fa-solid fa-house" @click="goMain()"></i><i class="fa-solid fa-chevron-right"></i>
          <a href="../media"><span class="txt">미디어</span></a><i class="fa-solid fa-chevron-right"></i>
          <a href="../media#report"><span class="txt">언론보도</span></a>
        </div>
      </div>
    </div>
      
    <section class="report" id="report">
      <div class="container">
        <div class="titArea">
          <h2>언론보도</h2>
          <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
        </div>
        <div class="conArea">
          <!-- contents 상세 start -->
          <div id="detail_004" class="detail" style="display: ;">
            <div class="topWrap">
              <h4 class="tit">메가존클라우드, KT클라우드 최고등급 '플래티넘 파트너' 선정</h4>
              <span class="name">관리자</span>
              <span class="date">2022. 10. 28</span>
            </div>
            <div class="conWrap">
              <div class="origin"><a href="https://news.mt.co.kr/mtview.php?no=2022102809192555221" target="_blank">원문보기</a></div>
              <div class="conImg">
                <img src="../assets/media_report_04.jpeg">
              </div>
              <span class="conTxt">
                클라우드 선도기업 메가존클라우드는 28일 KT클라우드로부터 최고 등급 파트너인 '플래티넘 파트너'로 선정됐다고 밝혔다.<br><br>
                KT클라우드의 전문 공인 파트너는 이번 메가존클라우드가 선정된 최고등급 플래티넘을 비롯해 △골드 △실버 △브론즈 등 4단계로 나뉜다. 매출 실적과 보유 고객 수, 교육 이수현황 등에 대한 전반적 평가 과정을 거쳐 선정된다.<br><br>
                메가존클라우드는 "KT클라우드의 최고 등급 파트너로 선정되며 공공 및 하이브리드 클라우드 부문에서의 기술 역량과 비즈니스 경쟁력을 입증했다"고 했다. 메가존클라우드는 앞서 지난 12일 진행된 '2022 KT클라우드 파트너스 데이'에도 플래티넘 파트너사로 참가해 국내 클라우드 생태계 활성화를 위한 협력 방안을 함께 논의하기도 했다.<br><br>
                메가존클라우드는 국내 및 아시아 최대 MSP(클라우드 관리 전문기업)로 클라우드와 AI(인공지능), 빅데이터 기술에 대한 고도의 역량을 확보하고 있다. 자체적으로 공공 클라우드 사업부와 하이브리드&AI 플랫폼 센터를 운영, 공공 및 하이브리드 클라우드에 특화된 자체 기술력을 확보하고 국내 기업들의 디지털 전환에 기여하고 있다.<br><br>
                윤동식 KT클라우드 대표는 "클라우드 산업은 양질의 IT 기술과 인프라를 제공하는 CSP(클라우드 서비스 사업자)와 IT 서비스를 구축·전환·운영하는 MSP간의 협업이 매우 중요하다"라며 "국내 최고 MSP인 메가존클라우드와 긴밀한 협업으로 클라우드 산업 성장과 발전에 기여하고, 함께 클라우드 생태계를 주도해 나갈 것"이라고 했다.<br><br>
                이주완 메가존클라우드 대표는 "이번 KT클라우드에서 가장 높은 등급인 플래티넘 파트너로 선정돼 그동안 축적해온 기술력과 비즈니스 역량을 입증하게 되어 기쁘다"라며 "앞으로 KT클라우드의 클라우드 역량 및 국내 최대 규모의 IDC(인터넷데이터센터)를 기반으로 퍼블릭·하이브리드 클라우드 사업에서 더욱 전략적으로 협업할 것"이라고 했다.<br><br>
                메가존클라우드의 고객군은 대기업, 중견·중소기업, 스타트업 등에 걸쳐 5,000여곳에 이른다. 올해 들어 상반기 KT로부터 1300억원의 투자를 유치한 데 이어 현재까지도 누적 8,000억원의 투자금을 확보한 상태다.<br><br>
                메가존클라우드와 메가존 등 관계사들에는 2250여명의 클라우드 전문인력이 있다. 지난해 이들 메가존 그룹 관계사들의 매출 합계는 9,300억원에 달했고 올해는 1조 4000억원을 무난히 달성할 것이라는 게 회사 설명이다. 현재 국내 뿐 아니라 미국, 캐나다, 중국 상하이·베이징, 일본, 베트남, 홍콩, 싱가포르 등 해외 현지 법인도 운영 중이다.
              </span>
            </div>
          </div>

          <div id="detail_003" class="detail" style="display: none;">
            <div class="topWrap">
              <h4 class="tit">공공 클라우드 전환 원년…KT클라우드 독주</h4>
              <span class="name">관리자</span>
              <span class="date">2022. 10. 23</span>
            </div>
            <div class="conWrap">
              <div class="origin"><a href="https://m.etnews.com/20221021000189" target="_blank">원문보기</a></div>
              <div class="conImg">
                <img src="../assets/media_report_03_1.jpeg">
              </div>
              <span class="conTxt">
                공공 클라우드 전환 사업 원년인 2022년 KT클라우드가 시장점유율 1위를 차지한 것으로 확인됐다.<br><br>
                행정안전부가 추진하는 2022년 '행정·공공기관 정보시스템 클라우드 전환·통합사업(1~6차)'에서 공공 클라우드 전환사업 대상 전체 128개 기관 가운데 54개 기관이 클라우드 서비스 제공사(CSP)로 KT클라우드를 선택했다.<br><br>
                1~6차 사업 결과, 기관 수 기준으로 KT클라우드가 42.2%를 점유하며 1위를 기록했다. 이어 NHN클라우드(17.2%), 네이버클라우드(15.6%), 가비아(12.5%), 삼성SDS(5.5%), 카카오엔터프라이즈(0.8%)가 뒤를 이었다.<br><br>
                1~6차 사업 정보시스템 기준으로도 전체 699개 가운데 264개 정보시스템이 KT클라우드로 전환, KT클라우드가 전체 시스템 53.6%를 차지했다. 네이버클라우드(17.5%), NHN클라우드(11.2%), 가비아(9.4%), 삼성SDS(2.1%), 카카오엔터프라이즈(0.7%) 순으로 점유했다.<br><br>
                5차 사업 수주전에서 공공 클라우드 전환사업 대상 37%가 CSP로 KT클라우드를 선택했다. KT클라우드에 이어 네이버클라우드가 22.2%를 점유하며 약진했다. 삼성SDS(14.8%), NHN클라우드(7.4%), 가비아(7.4%), 카카오엔터프라이즈(3.7%)가 뒤를 이었다.<br><br>
                시스템 기준으로는 KT클라우드(50.4%), 네이버클라우드(23.3%), 가비아(9.8%), 삼성SDS(6%), 카카오엔터프라이즈(3.8%), NHN클라우드(3%) 순으로 집계됐다.<br><br>
                6차 사업에서는 기관수 기준으로 KT클라우드(38.9%), NHN클라우드(30.6%), 네이버클라우드(16.7%), 삼성SDS(8.3%), 가비아(2.8%) 순으로 나타났다. 시스템수로도 비슷하다. KT클라우드(36.1%), 네이버클라우드(32.8%), NHN클라우드(18.9%), 삼성SDS(5.7%), 가비아(0.8%) 순이다.<br><br>
                정부가 2025년까지 모든 행정·공공기관 정보자원을 클라우드로 전환·통합을 추진하는 만큼 클라우드 기업간 경쟁 지속은 물론 갈수록 치열해질 전망이다.<br><br>
                클라우드 기업 관계자는 “공공 클라우드 시장은 전체 클라우드 사업의 마중물이자 교두보 역할을 한다”며 “CSP가 공공사업을 통해 제품·비즈니스 경쟁력을 향상시키는 것은 물론 연관된 민간 사업 확대를 위한 밑거름을 만들 수 있어 향후 사업에서 경쟁이 격화될 것”이라고 예상했다.
              </span>
            </div>
          </div>

          <div id="detail_002" class="detail" style="display: none;">
            <div class="topWrap">
              <h4 class="tit">KT클라우드 "올해 매출 6000억원…작년보다 30% 이상 성장할 것"</h4>
              <span class="name">관리자</span>
              <span class="date">2022. 06. 08</span>
            </div>
            <div class="conWrap">
              <div class="origin"><a href="https://www.mk.co.kr/news/it/10343319" target="_blank">원문보기</a></div>
              <div class="conImg">
                <img src="../assets/media_report_02.jpeg" class="media_report_02">
              </div>
              <div class="summaryWrap">
                <span class="summary">윤동식 대표, 법인 출정식서 밝혀</span>
                <span class="summary">2026년까지 2조원으로 확대 목표</span>
                <span class="summary">업계 1위 메가존과 사업 확장 나서</span>
              </div>
              <span class="conTxt">
                지난 4월 출범한 KT 클라우드가 법인 출정식을 8일 진행했다. 클라우드란 별도의 서버를 구축하지 않고도 언제 어디서나 인터넷에 접속해 회사 업무를 볼 수 있게 하는 IT시스템이다. 막대한 서버 구축 비용을 줄이면서 사용한 만큼만 구독료를 클라우드업체에게 내면 되기 때문에 최근 많은 기업들이 디지털화를 위해 클라우드를 도입하고 있다. 이날 행사에서 윤동식 KT 클라우드 대표는 올해 6000억원의 매출을 올려 전년 대비 30% 이상 성장하겠다고 포부를 밝혔다. 오는 2026년까진 2조원 규모로 매출을 확대하는 것이 목표다.<br><br>
                이를 위해 KT는 데이터센터 공급 확대, 클라우드관리서비스 제공사(MSP)와의 제휴 확대에도 나서겠다고 밝혔다. 메가존클라우드, 베스핀글로벌와 같은 MSP 업체들은 고객사 수요에 맞춰서 구글, 아마존(AWS) 등을 조합하는 역할을 맡는다. MSP와의 제휴 확대는 상대적으로 외산에 비해 후발주자인 KT클라우드를 고객사에게 '끼워 파는' 역할을 할 수 있다.<br><br>
                실제로 KT는 국내 1위 MSP 업체인 메가존클라우드에 1300억원 규모의 전략적 투자를 단행했다. 또한 KT와 메가존클라우드는 차세대 먹거리인 AICC(AI가 콜센터 직원 역할을 하는 것)를 선점하기 위해 시장을 공동 공략하기로 했다.<br><br>
                그동안 KT는 외산 클라우드가 접근 불가능한 '공공 분야'에 한해서 주로 입지를 다져왔는데, 메가존클라우드와 같은 민간 MSP와 손을 잡으면서 대기업, 금융사 등 민간 부문서도 시장을 확대해나갈 계획이다. 윤동식 대표는 "공공클라우드, AI클라우드 시장에서 리더십을 확보해 기업가치를 극대화하겠다"며 "전문성과 역량을 갖춘 인재 확보를 위해 노력하겠다"고 밝혔다.<br><br>
                한편 이날 KT는 인공지능(AI) 연구개발 포털 '지니랩스'를 열고 개방형 AI 생태계를 조성한다고 밝혔다. 지니랩스 홈페이지에 접속하면, 음성 인식, 음성 합성, 자연어처리, 비전AI 등 주요 AI 기술을 시연해볼 수 있다. 또한 상담사와 고객의 대화 중 주요 내용만 AI가 간추리는 '상담대화 요약', AI가 동일한 답변이라도 다양한 말투로 변환해 전달하는 '대화 스타일링' 등도 체험할 수 있다. 이밖에 AI 학습데이터 제공, KT와 AI스타트업이 함께 개발한 API 품질 테스트 등이 이뤄진다. KT 융합기술원 김이한 원장은 "KT는 앞으로도 지니랩스에 AI 스타트업과의 제휴 협력 사례들을 차례대로 소개할 것"이라며 "누구나 AI 연구개발에 자유롭게 참여하는 개방형 AI 생태계를 구축할 계획"이라고 설명했다.<br><br>
              </span>
            </div>
          </div>

          <div id="detail_001" class="detail" style="display: none;">
            <div class="topWrap">
              <h4 class="tit">[단독] KT, 메가존클라우드에 1300억 투자</h4>
              <span class="name">관리자</span>
              <span class="date">2022. 02. 15</span>
            </div>
            <div class="conWrap">
              <div class="origin"><a href="https://marketinsight.hankyung.com/article/202202143116r" target="_blank">원문보기</a></div>
              <div class="conImg">
                <img src="../assets/media_report_01.jpg">
              </div>
              <span class="conTxt">
                KT가 국내 1위 클라우드 관리 기업 메가존클라우드에 1300억원을 투자한다. 시장 선두기업과 파트너십을 형성해 본격적으로 클라우드 시장에 뛰어들 것으로 전망된다.<br><br>
                14일 투자은행(IB)업계에 따르면 KT는 메가존클라우드가 시행하는 유상증자에 참여하기로 했다. KT는 1300억원을 투자해 회사의 주요 주주에 오를 예정이다. 나머지 4000억원은 국내 1, 2위 사모펀드 운용사인 MBK파트너스와 IMM프라이빗에쿼티(PE)가 절반씩 나눠 투자하기로 했다.<br><br>
                KT는 그동안 투자 자회사인 KT인베스트먼트를 통해 메가존클라우드에 간접적으로 투자해왔지만 이번에 직접 투자를 통해 파트너십을 맺게 됐다. KT가 1000억원이 넘는 금액을 투자하는 건 케이뱅크 이후 처음이다. 메가존클라우드 투자는 디지털 전환 역량을 키우기 위한 전략으로 풀이된다. KT는 최근 디지털 플랫폼 기업(Digico·디지코)으로의 전환을 천명하고 인공지능(AI)·빅데이터·클라우드 부문을 핵심 사업으로 키우겠다고 밝혔다. 상반기 중 클라우드·IDC 사업을 분사하는 방안도 검토 중이다.<br><br>
                메가존클라우드는 클라우드의 도입부터 설계·구축·운영 등 관련 서비스를 제공하는 클라우드 MSP(관리서비스) 전문 기업이다. 메가존 소속 사업부로 있던 2012년 국내 최초로 아마존웹서비스(AWS) 협력사로 선정돼 업계의 이목을 끌었다. 2018년 물적분할됐고 두 차례의 외부 투자금 유치를 통해 기업가치를 불렸다. 현재 국내외에 3700개 이상의 고객사를 보유하고 있으며 미국과 캐나다 중국 일본 홍콩 베트남에 지사를 운영하고 있다.<br><br>
                최근 기업들이 클라우드를 기반으로 한 디지털 전환에 속도를 내면서 국내 클라우드 시장 규모도 빠르게 확대되고 있다. 업계에서는 국내 클라우드 MSP 시장 규모가 올해 5조원을 넘어설 것으로 전망한다. KT를 비롯해 삼성SDS와 LG CNS 등 기존 시스템통합(SI) 기업들은 MSP 분야를 미래 먹거리로 주목하고 속속 시장에 진출하고 있다.<br><br>
                NHN 역시 오는 4월 클라우드 사업부를 독립법인으로 출범시킬 예정이다. 이처럼 유망 분야로 꼽히는 클라우드 사업부를 분사하는 것은 대규모 외부 투자금을 유치하기 위해서다. 한 업계 관계자는 "클라우드 사업은 인프라 확보 등 초기 비용이 많이 든다"며 "전략적 투자자(FI) 유치를 통해 재원을 마련한 후 시장을 선점하려는 전략"이라고 설명했다.
              </span>
            </div>
          </div>
          <!-- contents 상세 end -->



          <div class="bottomWrap">
            <a href="../media"><button class="btnList"><i class="fa-solid fa-chevron-left"></i>목록으로</button></a>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>


<script>
export default {
  methods: {
    goMain(){
      this.$router.push({name: 'MainPage'})
    },
  }
}
</script>


