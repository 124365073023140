import Vue from "vue"
import Router from 'vue-router'
import Main from '@/views/MainPage'
import Sample from '@/views/SamplePage'
import service from '@/views/ServicePage'
import partner from '@/views/PartnerPage'
import event from '@/views/EventPage'
import promotion01 from '@/views/PromotionPage01'
import seminar01 from '@/views/SeminarPage01'
import seminar02 from '@/views/SeminarPage02'
import media from '@/views/MediaPage'
import report01 from '@/views/ReportPage01'
import report02 from '@/views/ReportPage02'
import report03 from '@/views/ReportPage03'
import report04 from '@/views/ReportPage04'
import report05 from '@/views/ReportPage05'
import report06 from '@/views/ReportPage06'
import video01 from '@/views/VideoPage01'
import video02 from '@/views/VideoPage02'
import video03 from '@/views/VideoPage03'
import video04 from '@/views/VideoPage04'
import video05 from '@/views/VideoPage05'
import contact from '@/views/ContactPage'
import notice from '@/views/NoticePage'
import privacy_policy from '@/views/PrivacyPolicyPage'

Vue.use(Router)


export default new Router({
    mode: 'history', // 라우터 '#'제거(UR이 변경될 때 페이지 다시 로드되게 가능),
    scrollBehavior () {
      return { x: 0, y: 0 }
    },
    routes: [
      {
        path: '/',
        name: 'MainPage',
        component: Main
      },
      {
        path: '/sample',
        name: 'SamplePage',
        component: Sample
      },
      {
        path: '/service',
        name: 'ServicePage',
        component: service
      },
      {
        path: '/partner',
        name: 'PartnerPage',
        component: partner
      },
      {
        path: '/event',
        name: 'EventPage',
        component: event
      },
      {
        path: '/promotion01',
        name: 'PromotionPage01',
        component: promotion01
      },
      {
        path: '/seminar01',
        name: 'SeminarPage01',
        component: seminar01
      },
      {
        path: '/seminar02',
        name: 'SeminarPage02',
        component: seminar02
      },
      {
        path: '/media',
        name: 'MediaPage',
        component: media
      },
      {
        path: '/report01',
        name: 'ReportPage01',
        component: report01
      },
      {
        path: '/report02',
        name: 'ReportPage02',
        component: report02
      },
      {
        path: '/report03',
        name: 'ReportPage03',
        component: report03
      },
      {
        path: '/report04',
        name: 'ReportPage04',
        component: report04
      },
      {
        path: '/report05',
        name: 'ReportPage05',
        component: report05
      },
      {
        path: '/report06',
        name: 'ReportPage06',
        component: report06
      },
      {
        path: '/video01',
        name: 'VideoPage01',
        component: video01
      },
      {
        path: '/video02',
        name: 'VideoPage02',
        component: video02
      },
      {
        path: '/video03',
        name: 'VideoPage03',
        component: video03
      },
      {
        path: '/video04',
        name: 'VideoPage04',
        component: video04
      },
      {
        path: '/video05',
        name: 'VideoPage05',
        component: video05
      },
      {
        path: '/contact',
        name: 'ContactPage',
        component: contact
      },
      {
        path: '/notice',
        name: 'NoticePage',
        component: notice
      },
      {
        path: '/privacy_policy',
        name: 'PrivacyPolicyPage',
        component: privacy_policy
      },
    ]
  })