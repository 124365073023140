<template>
  <div class="contents eventPage">
    <div class="topVisual">
      <div class="container">
        <h4>이벤트</h4>
        <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
      </div>
      <div class="location">
        <div class="container">
          <i class="fa-solid fa-house" @click="goMain()"></i><i class="fa-solid fa-chevron-right"></i>
          <a href="../event"><span class="txt">이벤트</span></a><i class="fa-solid fa-chevron-right"></i>
          <a href="../event#seminar"><span class="txt">세미나</span></a>
        </div>
      </div>
    </div>
      
    <section class="seminar" id="seminar">
      <div class="container">
        <div class="titArea">
          <h2>세미나</h2>
          <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
        </div>
        <div class="conArea">
          <!-- contents 상세 start -->
          <div id="detail_001" class="detail" style="display:;">
            <div class="topWrap"><h4 class="tit">K-Cloud Next Generation Managed Service “MSP 2.0”</h4><span class="name">관리자</span><span class="date">2023.12.12</span></div>
            <div class="conWrap">
              <div class="conImg">
                <a href="https://www.megazone.com/application_form_ktcloud-apply-231212/" target="_blank"><img src="../assets/seminar02.png"></a>
              </div>
            </div>
            <!-- contents 상세 end -->
            <div class="bottomWrap">
              <a href="../event#seminar"><button class="btnList"><i class="fa-solid fa-chevron-left"></i>목록으로</button></a>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  methods: {
    goMain(){
      this.$router.push({name: 'MainPage'})
    },
  }
}
</script>

