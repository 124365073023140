<template>
  <div class="contents mediaPage reportPage">
    <div class="topVisual">
      <div class="container">
        <h4>미디어</h4>
        <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
      </div>
      <div class="location">
        <div class="container">
          <i class="fa-solid fa-house" @click="goMain()"></i><i class="fa-solid fa-chevron-right"></i>
          <a href="../media"><span class="txt">미디어</span></a><i class="fa-solid fa-chevron-right"></i>
          <a href="../media#report"><span class="txt">언론보도</span></a>
        </div>
      </div>
    </div>
      
    <section class="report" id="report">
      <div class="container">
        <div class="titArea">
          <h2>언론보도</h2>
          <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
        </div>
        <div class="conArea">
          <!-- contents 상세 start -->
          <div id="detail_001" class="detail">
            <div class="topWrap">
              <h4 class="tit">한국IoT융합사업협동조합, 'MADE IN KOREA 2023' 콘퍼런스 개최</h4>
              <span class="name">관리자</span>
              <span class="date">2023. 08. 31</span>
            </div>
            <div class="conWrap">
              <div class="origin"><a href="https://www.etnews.com/20230831000303" target="_blank">원문보기</a></div>
              <div class="conImg">
                <img src="../assets/media_report_05.jpg">
              </div>
              <span class="conTxt">
                한국IoT융합사업협동조합(이사장 한재호)은 서울 양재 엘타워에서 '성공사례로 보는 공공 클라우드, MADE IN KOREA 2023' 콘퍼런스를 성황리에 마쳤다고 31일 밝혔다.<br><br>
                'MADE IN KOREA' 콘퍼런스는 정부의 디지털플랫폼정부 실현 계획에 발맞춰 공공 클라우드 확산 방안과 사례를 공유하고 혁신적인 기술을 소개하기 위해 개최됐다. 한국IoT융합사업협동조합과 전자신문이 주최하고 타이거컴퍼니가 주관, 과학기술정보통신부가 후원한 행사다.공공기관과 클라우드 시장을 선도하는 기업들이 모여 공공기관의 디지털 혁신 방향을 공유하며 상생을 도모하는 자리였다.<br><br>
                행사에는 한국지능정보사회진흥원(NIA)과 클라우드와 AI 분야 대표기업 kt cloud, 메가존클라우드, 에이쓰리시큐리티, 크리니티, 비즈니스온커뮤니케이션, 프라이빗테크놀로지, 타이거컴퍼니 등 7개사가 참가했다. 이밖에 민간 클라우드 도입을 고려하는 공공기관을 비롯해 금융, 통신, 유통, IT 등 민간 주요 산업계의 디지털 전환(DX) 담당자 280여 명이 참석했다. 콘퍼런스 관계자는 “콘퍼런스 사전 등록이 조기마감돼 계획에 없던 온라인 생중계를 추가하는 등 더 많은 참석자들이 콘퍼런스를 경험했다”고 전했다.<br><br>
                국회 과학기술정보방송통신위원회 소속 국민의힘 홍석준 의원은 영상 축사를 통해 “지난해 퍼블릭 클라우드 시장 규모는 700조 원을 넘어섰다”고 클라우드의 중요성을 강조하는 한편 “오늘 이 자리가 공공 클라우드 시장 성장과 우리나라 클라우드 기술력 향상에 도움이 되는 계기가 되기를 바란다”고 했다.<br><br>
                같은 위원회 소속 국민의힘 김영식 의원도 영상 축사에서 “공공부문에서도 클라우드를 통해 신속, 편리한 공공서비스를 제공해 혁신과 성장을 이끌고 있다”며 ”클라우드 산업 발전과 디지털 전환을 견인하기 위해 애쓰고 계신 많은 민관산학연 전문가에게 감사의 말씀을 전한다”고 말했다.<br><br>
                콘퍼런스는 '성공사례로 보는 공공 클라우드'라는 주제 하에 2개 기조강연과 4개의 발표로 진행됐다. 디지털 전환을 주도하는 공공기관과 중소벤처 IT기업은 공공 클라우드 비즈니스 사례, 클라우드 기술 등 공공 부문 클라우드의 방향성을 제시했다.<br><br>
                한국지능정보사회연구원(NIA)의 김은주 단장은 기조 강연을 통해 디지털플랫폼정부의 핵심 과제와 방향에 대해 클라우드 네이티브를 강조하면서 올해 정부가 역점을 두고 추진하는 '공공·민간 부문 클라우드 전환'과 '서비스형 소프트웨어(SaaS) 중심 소프트웨어산업 재설계' 등 정책과 업계 상생 방안 등을 공유했다.<br><br>
                kt cloud는 기조 강연에서 클라우드 사업을 진행하면서 쌓은 노하우를 기반으로 공공기관의 디지털 전환(DX)을 돕겠다는 포부를 전했다. kt cloud의 김주성 상무는 “AI, 클라우드, 인터넷데이터센터(IDC) 각 영역에서 지닌 강점을 바탕으로 고객의 다음 디지털전환(DX) 여정을 함께하겠다”며 “'풀 스택 AI 사업자'로 시장을 선도하겠다”고 밝혔다.<br><br>
                발표 세션에서는 첫 번째로 한국연구재단 차석일 팀장이 '공공기관 업무 혁신을 위한 SaaS 활용 사례'를 통해 공공기관의 협업툴 도입 과정을 소개하고 협업툴 활용 성과를 공유했다. 이어 타이거컴퍼니 조양호 상무가 '서울시 차세대 업무관리 S드라이브 구축 사례'를 통해 공공 클라우드 구축의 기술력과 노하우를 전했다.<br><br>
                세 번째 세션에서 비즈니스온커뮤니케이션의 이진일 전무는 소상공인시장진흥공단 등 공공분야 ESG와 DX 전환 사례를 발표하며 디지털 전환을 가속화할 수 있는 전자계약 활용 방법과 사례에 대해 소개했다. 네 번째 세션의 프라이빗테크놀로지 김영랑 대표는 안전한 공공 클라우드 이용 환경 구축 사례를 제로 트러스트 관점으로 소개하며 공공 클라우드 보안 기술을 공유했다.<br><br>
                한편, 대한민국 중소벤처 IT기업들이 클라우드 기술력을 공유하고 상생해 글로벌 시장까지 진출하자는 뜻을 담은 'MADE IN KOREA' 컨퍼런스는 올해로 처음 개최됐다. 전자신문과 행사를 주최한 한국IoT융합사업협동조합은 중소벤처 IT기업의 협력과 상생을 통해 공공기관 협업 플랫폼 NiCloud를 운영하고 있다.<br><br>
                한국IoT융합사업협동조합 한재호 이사장은 “국내 중소벤처 IT기업이 우수한 기술을 바탕으로 공공 클라우드 시장을 혁신하고 글로벌 진출에 성공할 수 있도록 조합이 함께 노력할 것”이라며 “앞으로도 민간 시장의 기업, 공공 시장의 기관들을 대상으로 'MADE IN KOREA' 컨퍼런스를 진행할 계획”이라고 전했다.<br>
              </span>
            </div>
          </div>
          <!-- contents 상세 end -->

          <div class="bottomWrap">
            <a href="../media"><button class="btnList"><i class="fa-solid fa-chevron-left"></i>목록으로</button></a>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>


<script>
export default {
  methods: {
    goMain(){
      this.$router.push({name: 'MainPage'})
    },
  }
}
</script>


