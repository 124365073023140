<template>
  <div class="footer">
    <div class="container">
      <div class="logoWrap"><span class="logo"></span></div>
      <div class="copyright">
        <div class="corp_list">
          <!-- <a href="#">서비스 이용약관</a> -->
          <a @click="goPath('/privacy_policy')" class="privacy">개인정보처리방침</a>
          <!-- <a href="#">이메일 무단수집거부</a> -->
        </div>
        <div class="corp_info">
          <div class="info_detail">
            <span>메가존클라우드㈜</span>
            <span>대표이사 : 이주완, 조원우</span>
            <span>사업자등록번호 : 232-88-00982</span>
          </div>
          <div class="info_detail">
            <span>서울특별시 강남구 논현로 85길 46, 메가존빌딩</span> 
            <span>대표번호 : 1644-2243</span>
            <span class="mail">메일 : ktcloud@megazone.com (상담 : 평일 09시~18시)</span>
          </div>
          <div class="copyTxt">©2023 <p>MEGAZONECLOUD</p> Corp. All Rights Reserved.</div>
        </div>
      </div>
    </div>
    <section class="banner">
      <a @click="goPath('/contact','#contactEmail')" href="#contactEmail">
        <i class="fa-solid fa-cloud-arrow-up"></i>
        <span class="bannerTxt">
          클라우드 도입이 처음이세요? 메가존클라우드가 도와드리겠습니다!
          <span class="bannerBtn">컨설팅 바로가기 <i class="fa-solid fa-chevron-right"></i></span>
        </span>
      </a>
    </section>
  </div>
</template>

<script>
  export default {
    methods: {
      goPath(selectedPath, hash){
        this.$router.push({
          path: selectedPath,
          hash: hash
        })
        if(hash!=undefined) this.path = selectedPath+hash
        else this.path = selectedPath
      },
      privacy() { 
        this.pop = window.open("/test/popup/popup", "privacy", "width=500, height=150");
      },
    },
    layout() {
      if (opener) {
        console.log('This is window.open');
        return 'popup'
      }
    },
    data() {
      return {
        pop: null,
      }
    }

  }
</script>
