<template>
    <div class="contents partnerPage">
        <div class="topVisual">
          <div class="container">
            <h4>고객사례</h4>
            <h5>다양한 분야의 고객사에 안정된 클라우드 통합 관리 서비스를 제공하고 있습니다.</h5>
          </div>

          <div class="location">
            <div class="container">
              <i class="fa-solid fa-house" @click="goMain()"></i><i class="fa-solid fa-chevron-right"></i>
              <a href="../partner"><span class="txt">고객사례</span></a>
            </div>
          </div>

        </div>

        <section class="partner">
          <div class="container">
            <div class="titArea">
              <h2>고객사례</h2>
              <h5>메가존클라우드의 고객 맞춤 kt cloud 도입사례를 소개합니다.</h5>
            </div>
            <div class="conArea">
              <div class="partnerWrap">
                <a class="ci_01"></a>
                <a class="ci_02"></a>
                <a class="ci_03"></a>
                <a class="ci_04"></a>
                <a class="ci_05"></a>
                <a class="ci_06"></a>
                <a class="ci_07"></a>
                <a class="ci_08"></a>
                <a class="ci_09"></a>
                <a class="ci_10"></a>
                <a class="ci_11"></a>
                <a class="ci_12"></a>
                <a class="ci_13"></a>
                <a class="ci_14"></a>
                <a class="ci_15"></a>
                <a class="ci_16"></a>
              </div>
            </div>
          </div>
        </section>

        
    </div>
</template>

<script>
export default {
  methods: {
    goMain(){
      this.$router.push({name: 'MainPage'})
    },
  }
}
</script>