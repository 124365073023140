<template>
  <div class="contents eventPage">
    <div class="topVisual">
      <div class="container">
        <h4>이벤트</h4>
        <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
      </div>
      <div class="location">
        <div class="container">
          <i class="fa-solid fa-house" @click="goMain()"></i><i class="fa-solid fa-chevron-right"></i>
          <a href="../event"><span class="txt">이벤트</span></a><i class="fa-solid fa-chevron-right"></i>
          <a href="../event#promotion"><span class="txt">프로모션</span></a>
        </div>
      </div>
    </div>
      
    <section class="promotion" id="promotion">
      <div class="container">
        <div class="titArea">
          <h2>프로모션</h2>
          <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
        </div>
        <div class="conArea">
          <!-- contents 상세 start -->
          <div id="detail_001" class="detail" style="display:;">
            <div class="topWrap"><h4 class="tit">메가존클라우드 신규 서비스 시연회</h4><span class="name">관리자</span><span class="date">2022.09.14</span></div>
            <div class="conWrap">
              <div class="conImg">
                <a href="http://www.naver.com"><img src="../assets/promotion01.png"></a>
              </div>
              <span class="conTxt">
                혈액이 끈끈해져 피떡 형태(혈전)가 되면 점차 혈관을 막아 혈액의 흐름에 문제가 생긴다. 혈관이 좁아지면 동맥경화, 아예 막히면 뇌졸중(뇌경색-뇌출혈), 심장병(협심증-심근경색)으로 이어진다. 중성지방, 콜레스테롤 등이 늘어나 피가 탁해지면 혈관이 딱딱해지고 좁아지는 동맥경화가 생긴다. 어떤 생활습관이 도움이 될까?
                ◆ 몸에서 요산 많이 검출되면... 심방세동, 혈전 위험 높아 국제 학술지 미국 심장협회 저널(Journal of the American Heart Association) 최신호에 몸에서 요산이 많이 검출되면 심방세동(심장 떨림) 위험이 높고 이로 인해 혈전이 만들어질 가능성도 증가한다는 논문이 실렸다. 심혈관 질환이 없는 34만여 명(30~60세)을 대상으로 평균 26년간 진행된 연구 자료를 분석한 결과다. 심방세동은 심장의 심방이 가늘게 떠는 증상이다.
                요산 수치가 가장 높은 사람들은 가장 낮은 사람보다 심방세동 발생률이 45% 높았다. 요산은 우리 몸의 단백질 대사 과정에서 부수적으로 생성되는 물질이다. 고단백 식사를 계속하면 요산 생성이 많아질 수 있다. 통풍, 유전, 대사의 이상으로 혈액 속 요산이 증가할 수 있다.
              </span>
            </div>
            <!-- contents 상세 end -->
            <div class="bottomWrap">
              <a href="../event#promotion"><button class="btnList"><i class="fa-solid fa-chevron-left"></i>목록으로</button></a>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  methods: {
    goMain(){
      this.$router.push({name: 'MainPage'})
    },
  }
}
</script>

