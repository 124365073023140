<template>
  <div class="contents contactPage">
      <div class="topVisual">
        <div class="container">
          <h4>문의하기</h4>
          <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
        </div>
        <div class="location">
          <div class="container">
            <i class="fa-solid fa-house" @click="goMain()"></i><i class="fa-solid fa-chevron-right"></i>
            <a href="../contact"><span class="txt">문의하기</span></a><i class="fa-solid fa-chevron-right"></i>
            <a href="../contact#notice"><span class="txt">공지사항</span></a>
          </div>
        </div>
      </div>
      
      <section class="notice" id="notice">
        <div class="container">
          <div class="titArea">
            <h2>공지사항</h2>
            <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
          </div>
          <div class="conArea">

            <!-- contents 상세 start -->
            <div id="detail_001" class="detail" style="display:;">
              <div class="topWrap"><h4 class="tit">메가존클라우드, KT클라우드 최고등급 '플래티넘 파트너' 선정</h4><span class="name">관리자</span><span class="date">2022. 09. 14</span></div>
              <div class="conWrap">
                <!-- <span class="original">동서대학교 대학뉴스  / 2021.09.06 / 종합홍보실 <a class="originalLink" href="http://www.dongseo.ac.kr/kr/?pCode=MN2000192&mode=view&idx=86369" target="_blank">기사본문보기</a></span> -->
                <span class="conTit">메가존클라우드, KT클라우드 최고등급 '플래티넘 파트너' 선정</span>
                <div class="conImg">
                  <img src="../assets/mainVisual03.png">
                </div>
                <span class="conTxt">
                  혈액이 끈끈해져 피떡 형태(혈전)가 되면 점차 혈관을 막아 혈액의 흐름에 문제가 생긴다. 혈관이 좁아지면 동맥경화, 아예 막히면 뇌졸중(뇌경색-뇌출혈), 심장병(협심증-심근경색)으로 이어진다. 중성지방, 콜레스테롤 등이 늘어나 피가 탁해지면 혈관이 딱딱해지고 좁아지는 동맥경화가 생긴다. 어떤 생활습관이 도움이 될까?
                  ◆ 몸에서 요산 많이 검출되면... 심방세동, 혈전 위험 높아 국제 학술지 미국 심장협회 저널(Journal of the American Heart Association) 최신호에 몸에서 요산이 많이 검출되면 심방세동(심장 떨림) 위험이 높고 이로 인해 혈전이 만들어질 가능성도 증가한다는 논문이 실렸다. 심혈관 질환이 없는 34만여 명(30~60세)을 대상으로 평균 26년간 진행된 연구 자료를 분석한 결과다. 심방세동은 심장의 심방이 가늘게 떠는 증상이다.
                  요산 수치가 가장 높은 사람들은 가장 낮은 사람보다 심방세동 발생률이 45% 높았다. 요산은 우리 몸의 단백질 대사 과정에서 부수적으로 생성되는 물질이다. 고단백 식사를 계속하면 요산 생성이 많아질 수 있다. 통풍, 유전, 대사의 이상으로 혈액 속 요산이 증가할 수 있다.
                </span>
              </div>
            </div>

            <!-- List start -->				
            <div id="noticeTable" class="contents table">
              <table class="noticeTable">
                  <caption></caption>
                <colgroup>
                  <col width="10%">
                  <col width="50%">
                  <col width="20%">
                  <col width="20%" class="m_none">
                </colgroup>
                <thead>
                  <tr>
                    <th width="10%">번호</th>
                    <th width="50%">제목</th>
                    <th width="20%" class="m_none">작성자</th>
                    <th width="20%">게시일</th>
                  </tr>
                </thead>
                <tbody id='page1' class="pageBody" style="display: none;">
                  <tr><td>21</td><td class="td_detail" id="td_detail_021"><a onclick="goDetailnotice('detail_021');" href="#notice"><span class="category">뉴스</span>동서대학교-이테크시스템 전략적 MOU체결</a></td><td class="m_none">관리자</td><td>2022-09-14</td></tr>
                  <tr><td>20</td><td class="td_detail" id="td_detail_020"><a onclick="goDetailnotice('detail_020');" href="#notice"><span class="category">세미나</span>학교를 위한 AWS 기반의 가상 실습실과 인공지능 플랫폼 세미나</a></td><td class="m_none">관리자</td><td>2022-09-06</td></tr>
                  <tr><td>19</td><td class="td_detail" id="td_detail_019"><a onclick="goDetailnotice('detail_019');" href="#notice"><span class="category">세미나</span>모두를 위한 AWS 데이터 분석 실습 세미나</a></td><td class="m_none">관리자</td><td>2022-09-03</td></tr>
                  <tr><td>18</td><td class="td_detail" id="td_detail_018"><a onclick="goDetailnotice('detail_018');" href="#notice"><span class="category">프로모션</span>메가존클라우드에서 대박 게임 런칭하자!</a></td><td class="m_none">관리자</td><td>2022-09-01</td></tr>
                  <tr><td>17</td><td class="td_detail" id="td_detail_017"><a onclick="goDetailnotice('detail_017');" href="#notice"><span class="category">뉴스</span>퀸텟시스템즈-메가존클라우드 전략적 MOU체결  </a></td><td class="m_none">관리자</td><td>2022-08-23</td></tr>
                  <tr><td>16</td><td class="td_detail" id="td_detail_016"><a onclick="goDetailnotice('detail_016');" href="#notice"><span class="category">교육</span>제주테크노파크를 위한 AWS General Immersion Day</a></td><td class="m_none">관리자</td><td>2022-07-20</td></tr>
                  <tr><td>15</td><td class="td_detail" id="td_detail_015"><a onclick="goDetailnotice('detail_015');" href="#notice"><span class="category">세미나</span>Healthcare와 AWS의 두근거리는 만남</a></td><td class="m_none">관리자</td><td>2022-07-10</td></tr>
                  <tr><td>14</td><td class="td_detail" id="td_detail_014"><a onclick="goDetailnotice('detail_014');" href="#notice"><span class="category">세미나</span>개발자를 위한 AWS A to Z : 개발부터 배포까지</a></td><td class="m_none">관리자</td><td>2022-07-09</td></tr>
                  <tr><td>13</td><td class="td_detail" id="td_detail_013"><a onclick="goDetailnotice('detail_013');" href="#notice"><span class="category">세미나</span>핀테크 클라우드 망분리 관점에서 보는 아키텍쳐 파헤치기</a></td><td class="m_none">관리자</td><td>2022-06-30</td></tr>
                  <tr><td>12</td><td class="td_detail" id="td_detail_012"><a onclick="goDetailnotice('detail_012');" href="#notice"><span class="category">교육</span>AWS General Immersion Day for Starter</a></td><td class="m_none">관리자</td><td>2022-06-04</td></tr>
                </tbody>
                <tbody id='page2' class="pageBody" style="display:;">
                  <tr><td>10</td><td class="td_detail" id="td_detail_010"><a onclick="goDetailnotice('detail_010');" href="#notice">AWS Partner Summit Online Korea 2022</a></td><td class="m_none">관리자</td><td>2022-05-03</td></tr>
                  <tr><td>9</td><td class="td_detail" id="td_detail_009"><a onclick="goDetailnotice('detail_009');" href="#notice">AWS Summit Online Korea 2022</a></td><td class="m_none">관리자</td><td>2022-05-03</td></tr>
                  <tr><td>8</td><td class="td_detail" id="td_detail_008"><a onclick="goDetailnotice('detail_008');" href="#notice">대 코로나 시대, AWS 보안 백신 프로젝트!</a></td><td class="m_none">관리자</td><td>2022-05-03</td></tr>
                  <tr><td>7</td><td class="td_detail" id="td_detail_007"><a onclick="goDetailnotice('detail_007');" href="#notice">AWS DB Service로 쉽고 빠른 Migration</a></td><td class="m_none">관리자</td><td>2022-05-03</td></tr>
                  <tr><td>6</td><td class="td_detail" id="td_detail_006"><a onclick="goDetailnotice('detail_006');" href="#notice">성공적인 비즈니스를 위한 SECURITY with AWS &amp; 메가존 클라우드</a></td><td class="m_none">관리자</td><td>2022-03-30</td></tr>
                  <tr><td>5</td><td class="td_detail" id="td_detail_005"><a onclick="goDetailnotice('detail_005');" href="#notice">동양미래대학교 &amp; 메가존클라우드 마이스터대 시범운영 사업 추진을 위한 MOU 체결</a></td><td class="m_none">관리자</td><td>2022-03-15</td></tr>
                  <tr><td>4</td><td class="td_detail" id="td_detail_004"><a onclick="goDetailnotice('detail_004');" href="#notice">광주AI창업캠프 입주사를 위한 AWS Immersion Day with Cloud</a></td><td class="m_none">관리자</td><td>2022-03-04</td></tr>
                  <tr><td>3</td><td class="td_detail" id="td_detail_003"><a onclick="goDetailnotice('detail_003');" href="#notice">메가존클라우드 빌링 솔루션 ‘Billing-On’ 오픈</a></td><td class="m_none">관리자</td><td>2022-03-04</td></tr>
                  <tr><td>2</td><td class="td_detail" id="td_detail_002"><a onclick="goDetailnotice('detail_002');" href="#notice">광주형 인공지능 비즈니스 기반 조성을 위한 광주광역시 &amp; 메가존클라우드 MOU 체결</a></td><td class="m_none">관리자</td><td>2022-03-04</td></tr>
                  <tr><td>1</td><td class="td_detail active" id="td_detail_001"><a onclick="goDetailnotice('detail_001');" href="#notice">메가존클라우드, KT클라우드 최고등급 '플래티넘 파트너' 선정</a></td><td class="m_none">관리자</td><td>2022-03-04</td></tr>
                </tbody>
                <tbody id='page3' class="pageBody" style="display:none;">
                  <tr><td>1</td><td class="td_detail" id="td_detail_001"><a onclick="goDetailnotice('detail_001');" href="#notice"><span class="category">교육</span>Early Startup General Immersion Day AWS &amp; Youngwoo Digital</a></td><td class="m_none">관리자</td><td>2022-03-04</td></tr>
                </tbody>
                </table>

                <div id="paging" class="page">
                  <ol>
                    <li id="pageNum_1" class="pageNumber select" onclick="selectPageNumber(1)"><a>1</a></li>
                    <li id="pageNum_2" class="pageNumber "       onclick="selectPageNumber(2);"><a>2</a></li>
                    <li id="pageNum_3" class="pageNumber "       onclick="selectPageNumber(3);"><a>3</a></li>
                  </ol>
                </div>
            </div><!-- List END -->
          </div>
        </div>
      </section>

  </div>
</template>


<script>
export default {
  data () {
    return {
      page: 1,
    }
  },
  methods: {
    goMain(){
      this.$router.push({name: 'MainPage'})
    },
  }
}
</script>


