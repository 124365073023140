<template>
  <div class="contents mediaPage reportPage">
    <div class="topVisual">
      <div class="container">
        <h4>미디어</h4>
        <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
      </div>
      <div class="location">
        <div class="container">
          <i class="fa-solid fa-house" @click="goMain()"></i><i class="fa-solid fa-chevron-right"></i>
          <a href="../media"><span class="txt">미디어</span></a><i class="fa-solid fa-chevron-right"></i>
          <a href="../media#report"><span class="txt">언론보도</span></a>
        </div>
      </div>
    </div>
      
    <section class="report" id="report">
      <div class="container">
        <div class="titArea">
          <h2>언론보도</h2>
          <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
        </div>
        <div class="conArea">
          <!-- contents 상세 start -->
          <div id="detail_001" class="detail">
            <div class="topWrap">
              <h4 class="tit">AI, Cloud, IDC 산업 관련 기관∙기업 관계자 한자리에∙∙∙‘kt cloud summit 2023’ 개최 DX 혁신 기술∙전략 공유</h4>
              <span class="name">관리자</span>
              <span class="date">2023. 04. 18</span>
            </div>
            <div class="conWrap">
              <div class="origin"><a href="https://www.aitimes.kr/news/articleView.html?idxno=27819" target="_blank">원문보기</a></div>
              <div class="conImg">
                <img src="../assets/media_report_03_1.jpg">
              </div>
              <span class="conTxt">
                대한민국 인공지능, 클라우드, IDC 업계 관계자∙전문가가 디지털 비즈니스 가치 실현을 위한 지속 가능한 기술과 생태계 성장 비전을 확인하는 자리가 열렸다.<br><br>
                kt cloud(대표이사 윤동식)는 18일 그랜드 인터컨티넨탈 서울 파르나스에서 ‘kt cloud summit 2023’을 성황리에 개최됐다.<br><br>
                이날 행사에는 대한민국 디지털 산업을 이끌고 있는 대기업, IT 전문기업, 스타트업, 한국정보사회진흥원(NIA) 등 관련 공공 기관, DX 비즈니스 추진 기업의 대표 및 주요 인사 1,000여명이 참석해 자리를 빛냈다. <br><br>
                국내∙외 AI, Cloud, IDC 업계 관계자들이 한자리에 모여 DX 산업의 현재와 미래를 공유하고 생태계 동반성장과 비전을 확인하는 의미 있는 시간을 가졌다.<br><br>
                kt cloud summit 2023은 ‘디지털 비즈니스 혁신을 위한 AI Cloud, Cloud, IDC 산업’을 주제로 산업 동향과 기술, 전략 공유를 위한 3개의 키노트와 총 3개 트랙 30개 전문 분야의 세션, 업계 최신 기술과 서비스 전시가 함께 진행됐다. <br>
                출처 : 인공지능신문(https://www.aitimes.kr)
              </span>
              <div class="conImg">
                <img src="../assets/media_report_03_2.jpg">
              </div>
              <span class="conTxt">
                오전에는 kt cloud 윤동식 대표의 환영사를 시작으로 윤동식 대표, NIA 황종성 원장, KT 융합기술원 AI2XL연구소 배순민 소장의 키노트가 이어졌다.<br><br>
                ▷윤동식 대표의 키노트는 ‘Your Next DX journey with AI, Cloud, IDC’를 주제로 진행됐다. 초거대 AI 시대 한국 기업 경쟁력 기반의 새로운 비즈니스 기회 창출과 디지털 재난 대비 및 비즈니스 연속성을 위한 Resilient 인프라의 중요성이 대두되고 있다며, 이를 위해 필요한 핵심 요소는 AI, Cloud, IDC라고 강조했다.<br><br>
                첫번째 요소로 AI 인프라 비용 장벽 해소와 AI 신규 비즈니스를 위한 ‘하이퍼스케일 AI(Hyperscale AI)’를 소개했다. 스캐터랩의 AI 챗봇 ‘이루다’ 사례를 통해 세계 최초 다중∙동적 할당 기술을 적용한 AI 인프라 서비스 HAC(Hyperscale AI Computing)을 소개하며, 초거대 AI 개발∙학습의 자원, 비용, 시간 부담을 극복 방안을 공유했다.<br><br>
                두번째 요소로 다양해지고 구체화된 고객별 요구사항 맞춤형 IT 환경을 제공하는 ‘Customized Cloud’를 소개하며, '공공’, ‘금융’, ‘엔터프라이즈’ 각 시장의 정책 현황과 니즈를 분석했다. 서비스 차별화와 라인업 확대, 파트너 상생을 통한 생태계 확장을 통한 고객 요구 충족 방안을 소개하며, MSP∙솔루션사와의 협력 지원 강화 방침도 공유했다.<br><br>
                마지막으로 어떠한 상황에서도 서비스 연속성을 확보할 수 있는 ‘Resilient Infra’에 대해, DR의 중요성과 고객 상황에 맞는 DR전략 수립이 중요하다고 설명했다. IDC의 지리적 분산, 이중화∙이원화 설비, One-IDC 네트워크 연결의 중요성과 클라우드, IDC, On-premise를 연결하는 HCX(Hyper connectivity Exchange)바탕의 Resilient & Flexible 디지털 인프라를 실현할 수 있다고 강조했다.<br><br>
                ▷두번째 키노트에서는 한국지능정보사외진흥원(NIA) 황종성 원장이 ‘디지털플랫폼정부 핵심 인프라: 클라우드 정부 정책과 방향’을 소개했다. 23년 정부가 역점을 두고 추진하고 있는 '공공/민간 부문 클라우드 전환'과 ‘K-클라우드 프로젝트’,  'SaaS 중심 SW산업 재설계' 등 정책과 업계 상생 방안, NIA의 계획 등에 대해 공유했다.<br><br>
                ▷마지막 키노트는 KT 융합기술원 AI2XL연구소 배순민 소장이 맡았다. 배 소장은 초거대 AI의 대중화를 이끈 챗GPT(ChatGPT)의 등장 후, 클라우드 사업자들은 경쟁적으로 초거대 AI 모델을 자사 플랫폼으로 유치하기 위해서 노력하고 있다고 소개하며, 초거대 AI 모델은 이미 그 자체로도 파급력을 가지는 인프라∙플랫폼의 성격을 가지며, 클라우드 시장의 성장을 리딩할 것으로 예상했다. <br><br>
                이어 KT는 초거대 AI 모델 ‘믿:음’을 통해 고객들의 삶의 변화에 기여하고 있으며, 고객 누구나 쉽게 초거대 AI 기술을 활용할 수 있도록 클라우드 기반 AI 풀스택을 제공할 것이라고 강조했다.<br><br>
                ▷오후에는 AI, 클라우드, IDC 분야 3개 트랙, 총 30개 세션에서 AI, IDC, 공공, SaaS, DaaS, DR, DX 을 주제로 발표가 이어졌다. kt cloud 뿐만 아니라 각 분야 전문 기업의 임원 등 대표자가 직접 연사로 나서 업계 관계자들의 호응을 얻었다.<br><br>
                AI 분야 세션에서는 초거대 AI 시대를 맞아 최신 시장 동향과 AI 비즈니스를 위해 필요한 기술과 서비스가 소개됐다. 특히 시장과 기업들이 겪고 있는 비즈니스 진출, 확대 시 장벽들을 해소할 수 있는 방안으로 참가자들의 이목을 집중 시켰다.<br><br>
                kt cloud의 HAC 서비스와 스캐터랩의 도입 성공 사례와 전략, 리벨리온의 HW/SW 솔루션, 모레의 소프트웨어, 메가존클라우드의 플랫폼과 KT의 AI B2B 서비스 인프라 구축 전략 등이 공유됐다.<br><br>
                클라우드 분야에서는 업계 화두로 떠오르는 ‘DaaS’ 플랫폼 소개와 함께 틸론의 행정 기관의 VDI 도입 사례, 시트릭스의 솔루션이 소개됐고, ‘공공 클라우드’ 주제로는 정부의 클라우드 정책 방향과 대응 전략이 소개되며 디딤365의 공공 전환 사업 사례와 노하우가 공개되어 관심을 끌었다. <br><br>
                이 밖에도 ‘하이브리드 클라우드’, ‘컨테이너’, ‘SaaS’, ‘SDDS’ ‘금융 클라우드’ 등 고객별 환경에 필요한 다양한 서비스 모델과 전략이 공유되며, 고객 맞춤형 클라우드의 중요성에 대해 공감했다.<br><br>
                IDC 세션에서는 ‘DX’의 핵심 요소로 키노트에서도 강조됐던 비즈니스 연속성을 위한 ‘DR’ 구축∙실현이 주요하게 다뤄지며, HPE의 재해복구 솔루션과 자다라의 DRaaS, 베리타스코라아의 전략과 사례가 관심을 모았다. <br><br>
                또 ‘DX’를 위한 글로벌 기업들의 인사이트 및 전략으로 Dell의 고객 사례, 인텔의 Granulate 플랫폼, 레노버의 스토리지 솔루션 등이 주목받았다. 이밖에 지속 가능한 경영, 탄소 중립을 위한 ‘IDC ESG’ 기술과 AI, 로봇 등을 접목하는 최신 데이터센터 기술 트렌드, 지능형 데이터센터와 One-IDC 네트워크, 인터커넥션 서비스가 소개됐다.<br>
                출처 : 인공지능신문(https://www.aitimes.kr)
              </span>
              <div class="conImg">
                <img src="../assets/media_report_03_3.jpg">
              </div>
              <span class="conTxt">
                이날 행사에 설치된 kt cloud 전시 부스에서는 초거대 AI 모델 개발을 위해 대규모 GPU 자원을 종량제 방식으로 제공하는 HAC, 기업이 원하는 다양한 유형의 안정적인 백업 환경을 소개하는 DR 서비스, 가상 데스크톱과 앱을 통해 유연한 근무환경을 제공하는 DaaS 서비스가 관심을 모았다.<br><br>
                이 밖에도 DDoS 공격에 대비하는 보안 서비스 클린존, 고객 전용 가상 데이터 센터 서비스 SDDC, 하이브리드 클라우드 CloudFarm, DBaaS for MySQL 8, Kubernetes Pack 등이 전시됐다.<br><br>
                총 15개 분야별 전문 기업이 행사장 내 전시에 참여해 최신 기술∙서비스 소개와 함께 고객사 상담을 진행해 비즈니스 실현의 열기도 느낄 수 있었다. AMD는 AI, 클라우드 솔루션에 최적화된 고성능 인프라를 소개했으며, 국내 대표 MSP인 메가존클라우드 매니지드 서비스와 AI 플랫폼, 디딤365는 클라우드 관리 플랫폼을 전시했다. <br><br>
                또 인텔, 레노버, Dell 등 글로벌 기업들은 클라우드 도입에 필요한 스토리지와 솔루션을, HPE-베이넥스, 베리타스는 재해복구 솔루션을 시연했다. 이 밖에도 IDC 분야에서는 삼화에이스는 ESG 관련 기술과 자다라의 글로벌 로케이션 서비스가 전시됐다.<br><br>
                kt cloud 윤동식 대표는 “kt cloud summit 2023은 전문 업계 관계자가 모여 기술과 전략을 공유하고, 파트너 동반성장 및 생태계 비전을 확인한 뜻 깊은 자리였다. 앞으로도 디지털 비즈니스 가치 실현을 위한 지속 가능한 기술을 교류하며 파트너들과 함께 대한민국 DX 산업을 이끌어 가겠다”고 밝혔다.
              </span>
            </div>
          </div>
          <!-- contents 상세 end -->

          <div class="bottomWrap">
            <a href="../media"><button class="btnList"><i class="fa-solid fa-chevron-left"></i>목록으로</button></a>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>


<script>
export default {
  methods: {
    goMain(){
      this.$router.push({name: 'MainPage'})
    },
  }
}
</script>


