<template>
  <div class="contents mediaPage reportPage">
    <div class="topVisual">
      <div class="container">
        <h4>미디어</h4>
        <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
      </div>
      <div class="location">
        <div class="container">
          <i class="fa-solid fa-house" @click="goMain()"></i><i class="fa-solid fa-chevron-right"></i>
          <a href="../media"><span class="txt">미디어</span></a><i class="fa-solid fa-chevron-right"></i>
          <a href="../media#report"><span class="txt">언론보도</span></a>
        </div>
      </div>
    </div>
      
    <section class="report" id="report">
      <div class="container">
        <div class="titArea">
          <h2>언론보도</h2>
          <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
        </div>
        <div class="conArea">
          <!-- contents 상세 start -->
          <div id="detail_001" class="detail">
            <div class="topWrap">
              <h4 class="tit">[단독] KT, 메가존클라우드에 1300억 투자</h4>
              <span class="name">관리자</span>
              <span class="date">2022. 02. 15</span>
            </div>
            <div class="conWrap">
              <div class="origin"><a href="https://marketinsight.hankyung.com/article/202202143116r" target="_blank">원문보기</a></div>
              <div class="conImg">
                <img src="../assets/media_report_01.jpg">
              </div>
              <span class="conTxt">
                KT가 국내 1위 클라우드 관리 기업 메가존클라우드에 1300억원을 투자한다. 시장 선두기업과 파트너십을 형성해 본격적으로 클라우드 시장에 뛰어들 것으로 전망된다.<br><br>
                14일 투자은행(IB)업계에 따르면 KT는 메가존클라우드가 시행하는 유상증자에 참여하기로 했다. KT는 1300억원을 투자해 회사의 주요 주주에 오를 예정이다. 나머지 4000억원은 국내 1, 2위 사모펀드 운용사인 MBK파트너스와 IMM프라이빗에쿼티(PE)가 절반씩 나눠 투자하기로 했다.<br><br>
                KT는 그동안 투자 자회사인 KT인베스트먼트를 통해 메가존클라우드에 간접적으로 투자해왔지만 이번에 직접 투자를 통해 파트너십을 맺게 됐다. KT가 1000억원이 넘는 금액을 투자하는 건 케이뱅크 이후 처음이다. 메가존클라우드 투자는 디지털 전환 역량을 키우기 위한 전략으로 풀이된다. KT는 최근 디지털 플랫폼 기업(Digico·디지코)으로의 전환을 천명하고 인공지능(AI)·빅데이터·클라우드 부문을 핵심 사업으로 키우겠다고 밝혔다. 상반기 중 클라우드·IDC 사업을 분사하는 방안도 검토 중이다.<br><br>
                메가존클라우드는 클라우드의 도입부터 설계·구축·운영 등 관련 서비스를 제공하는 클라우드 MSP(관리서비스) 전문 기업이다. 메가존 소속 사업부로 있던 2012년 국내 최초로 아마존웹서비스(AWS) 협력사로 선정돼 업계의 이목을 끌었다. 2018년 물적분할됐고 두 차례의 외부 투자금 유치를 통해 기업가치를 불렸다. 현재 국내외에 3700개 이상의 고객사를 보유하고 있으며 미국과 캐나다 중국 일본 홍콩 베트남에 지사를 운영하고 있다.<br><br>
                최근 기업들이 클라우드를 기반으로 한 디지털 전환에 속도를 내면서 국내 클라우드 시장 규모도 빠르게 확대되고 있다. 업계에서는 국내 클라우드 MSP 시장 규모가 올해 5조원을 넘어설 것으로 전망한다. KT를 비롯해 삼성SDS와 LG CNS 등 기존 시스템통합(SI) 기업들은 MSP 분야를 미래 먹거리로 주목하고 속속 시장에 진출하고 있다.<br><br>
                NHN 역시 오는 4월 클라우드 사업부를 독립법인으로 출범시킬 예정이다. 이처럼 유망 분야로 꼽히는 클라우드 사업부를 분사하는 것은 대규모 외부 투자금을 유치하기 위해서다. 한 업계 관계자는 "클라우드 사업은 인프라 확보 등 초기 비용이 많이 든다"며 "전략적 투자자(FI) 유치를 통해 재원을 마련한 후 시장을 선점하려는 전략"이라고 설명했다.
              </span>
            </div>
          </div>
          <!-- contents 상세 end -->

          <div class="bottomWrap">
            <a href="../media"><button class="btnList"><i class="fa-solid fa-chevron-left"></i>목록으로</button></a>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>


<script>
export default {
  methods: {
    goMain(){
      this.$router.push({name: 'MainPage'})
    },
  }
}
</script>


