<template>
    <div class="contents eventPage">
        <div class="topVisual">
          <div class="container">
            <h4>이벤트</h4>
            <h5>메가존클라우드에서 준비한 특별한 서비스와 혜택을 확인하세요.</h5>
          </div>

          <div class="location">
            <div class="container">
              <i class="fa-solid fa-house" @click="goMain()"></i><i class="fa-solid fa-chevron-right"></i>
              <a href="../event"><span class="txt">이벤트</span></a>
            </div>
          </div>

        </div>
        <section class="seminar" id="seminar">
          <div class="container">
            <div class="titArea">
              <h2>세미나</h2>
              <h5>세미나 일정과 다양한 정보를 확인하세요.</h5>
            </div>
            <div class="conArea">
              <div class="list" style="display: ;">
                <a class="part" href="seminar02">
                  <div class="thumb seminar_thumb_02">
                    <h3>K-Cloud Next Generation Managed Service <br>“MSP 2.0”</h3>
                    <h5>2023. 12. 12</h5>
                  </div>
                  <h4>K-Cloud Next Generation <br>Managed Service “MSP 2.0”</h4>
                  <span class="date">2023. 12. 12</span>
                </a>
                <a class="part" href="seminar01">
                  <div class="thumb seminar_thumb_01">
                    <h3>Hyper Connectivity <br>사업협력 모델소개</h3>
                    <h5>2023. 08. 03(목) 14:00~15:00</h5>
                  </div>
                  <h4>kt cloud와 함께하는, <br>Hyper Connectivity 사업협력 모델소개</h4>
                  <span class="date">2023. 08. 03</span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section class="promotion" id="promotion">
          <div class="container">
            <div class="titArea">
              <h2>프로모션</h2>
              <h5>메가존클라우드에서 준비한 특별한 서비스와 혜택을 확인하세요.</h5>
            </div>
            <div class="conArea">
              <div class="list">
                <a class="part" href="../promotion" style="display: none;">
                  <div class="thumb promotion_thumb_001">
                    <h3>공공클라우드 <br>신규문의 프로모션</h3>
                    <h5>2023. 03. 06(월) ~ 소진 시까지</h5>
                    <a class="moreBtn">지금 문의하기 <i class="fa-solid fa-chevron-right"></i></a>
                  </div>
                  <h4>클라우드 고민 해결사, 메가존클라우드에 지금 문의하고 특별 사은품 받아가세요</h4>
                  <span class="date">2023. 03. 06(월) ~ 소진 시까지</span>
                </a>
                <!-- <a class="part" href="../promotion">
                  <div class="thumb promotion_thumb_002">
                    <h3>메가존클라우드 <br>신규 서비스 시연회</h3>
                    <h5>2023. 03. 12(수) PM. 04:00</h5>
                  </div>
                  <h4>메가존클라우드 신규 서비스 시연회</h4>
                  <span class="date">2023.01.19</span>
                </a>
                <a class="part" href="../promotion">
                  <div class="thumb promotion_thumb_003">
                    <h3>메가존클라우드 <br>시연회 프로모션 참여!</h3>
                    <h5>2023. 02. 01(수) PM. 04:00</h5>
                  </div>
                  <h4>메가존클라우드 신규 서비스 시연회</h4>
                  <span class="date">2023.01.19</span>
                </a>
                <a class="part" href="../promotion">
                  <div class="thumb promotion_thumb_004">
                    <h3>프로모션 참여하고 <br>푸짐한 선물 받으세요!</h3>
                    <h5>2023. 02. 01(수) PM. 04:00</h5>
                  </div>
                  <h4>메가존클라우드 신규 서비스 시연회</h4>
                  <span class="date">2023.01.19</span>
                </a> -->
              </div>
            </div>
          </div>
        </section>
    </div>
</template>

<script>
export default {
  methods: {
    goMain(){
      this.$router.push({name: 'MainPage'})
    },
  }
}
</script>