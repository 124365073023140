<template>
  <div class="topMnWrap" :class="{'topMnScroll': !showNavbar}" >
    <div class="topMnInner">
      <div class="logo" @click="goMain()"></div>
      <a class="contactBtn" @click="goPath('/contact','#contactEmail')" href="#contactEmail">
        <i class="fa-solid fa-comment fa-comment"></i>
        <i class="fa-solid fa-comment fa-commentR"></i>
        <span class="txt">문의하기<i class="fa-solid fa-chevron-right"></i></span>
        <div class="ask">
          <span>메일문의 : ktcloud@megazone.com </span>
        </div>
      </a>
      <div class="mnList">
        <ul>
          <li :class="{ active : path.match(/service.*/)}" @click="goPath('/service')"><a>서비스</a></li>
          <!-- <li class=""><a href="../partner">고객사례</a></li> -->
          <li :class="{ active : path.match(/partner.*/)}" @click="goPath('/partner')"><a>고객사례</a></li>
          <li :class="{ active : path.match(/event.*/)}" @click="goPath('/event')"><a>이벤트</a></li>
          <li :class="{ active : path.match(/media.*/)}" @click="goPath('/media')"><a>미디어</a></li>
          <li :class="{ active : path.match(/contact.*/)}" @click="goPath('/contact')"><a>문의하기</a></li>
        </ul>
      </div>
    </div>

    <div class="subMnWrap" style="display: ;">
      <div class="subMnInner">
        <div class="subMnList">
          <!-- <a><span class="subMn" :class="{ active : path=='/contact'}" @click="goPath('/contact')">자료실</span></a> -->
          <!-- <a href="#notice"><span class="subMn" :class="{ active : path=='/contact#notice'}" @click="goPath('/contact','#notice')">공지사항</span></a> -->
          <a href="#contactEmail"><span class="subMn" :class="{ active : path=='/contact#contactEmail'}" @click="goPath('/contact','#contactEmail')">문의하기</span></a>
        </div>
        <div class="subMnList">
          <a><span class="subMn" :class="{ active : (path=='/media')}" @click="goPath('/media')">언론보도</span></a>
          <a href="#video"><span class="subMn" :class="{ active : path=='/media#video'}" @click="goPath('/media','#video')">홍보영상</span></a>
          <a href="https://mzktcloud-techblog.oopy.io/" target="_blank"><span class="subMn" :class="{ active : path=='/media#blog'}">기술 블로그</span></a>
        </div>
        <div class="subMnList">
          <a href="#seminar"><span class="subMn" :class="{ active : path=='/event#seminar'}" @click="goPath('/event','#seminar')">세미나</span></a>
          <a href="#promotion"><span class="subMn" :class="{ active : path=='/event'}" @click="goPath('/event')">프로모션</span></a>
        </div>
        <div class="subMnList">
          <span class="subMn" :class="{ active : path=='/partner'}" @click="goPath('/partner')">고객사례</span>
        </div>
        <div class="subMnList">
          <!-- <router-link to="/service"><span class="subMn" :class="{ active : path=='/service'}" @click="goPath('/service')">Why KTC on MZC?</span></router-link> -->
          <router-link to="/service?tabItem=publicCloud"><span  class="subMn" :class="{ active : path=='/service#publicCloud'}" @click="goPath('/service','#publicCloud')">공공 클라우드</span></router-link>
          <router-link to="/service?tabItem=cloud"><span class="subMn" :class="{ active : path=='/service#cloud'}" @click="goPath('/service','#cloud')">민간 클라우드</span></router-link>
          <!-- <router-link to="/service?tabItem=dr"><span class="subMn" :class="{ active : path=='/service'}">마틸다 AI 플랫폼</span></router-link> -->
          <!-- <router-link to="/service?tabItem=dr"><span class="subMn" :class="{ active : path=='/service#dr'}" @click="goPath('/service','#dr')">DR as a Service</span></router-link> -->
        </div>
      </div>
    </div>

    <div class="m_topMn">
      <div class="logo" @click="goMain()"></div>

      <div class="sidebarMn">
        <b-button v-b-toggle.sidebar-right class="mnBtn">
          <span></span>
          <span></span>
          <span></span>
        </b-button>

        <b-sidebar id="sidebar-right" right shadow>
          <div class="innerMn">
            <div class="innerLogo" @click="goMain()"></div>
            <ul>
              <li :class="{ active : path.match(/service.*/)}">
                <a v-b-toggle.collapse-1 class="topMn" href="../service">서비스</a>
                <div class="subMnList">
                  <router-link to="/service"><span class="subMn" :class="{ active : path=='/service'}" @click="goPath('/service')">Why KTC on MZC?</span></router-link>
                  <router-link to="/service?tabItem=publicCloud"><span class="subMn" :class="{ active : path=='/service#publicCloud'}" @click="goPath('/service','#publicCloud')">공공 클라우드</span></router-link>
                  <router-link to="/service?tabItem=cloud"><span class="subMn" :class="{ active : path=='/service#cloud'}" @click="goPath('/service','#cloud')">민간 클라우드</span></router-link>
                  <!-- <router-link to="/service?tabItem=dr"><span class="subMn" :class="{ active : path=='/service#dr'}" @click="goPath('/service','#dr')">DR as a Service</span></router-link> -->
                </div>
              </li>
              <li :class="{ active : path.match(/partner.*/)}">
                <a @click="goPath('/partner')">고객사례</a>
                <div class="subMnList">
                  <a><span class="subMn" :class="{ active : path=='/partner'}" @click="goPath('/partner')">고객사례</span></a>
                </div>
              </li>
              <li :class="{ active : path.match(/event.*/)}">
                <a @click="goPath('/event')">이벤트</a>
                <div class="subMnList">
                  <a href="#seminar"><span class="subMn" :class="{ active : path=='/event#seminar'}" @click="goPath('/event','#seminar')">세미나</span></a>
                  <a href="#promotion"><span class="subMn" :class="{ active : path=='/event'}" @click="goPath('/event')">프로모션</span></a>
                </div>
              </li>
              <li :class="{ active : path.match(/media.*/)}">
                <a @click="goPath('/media')">미디어</a>
                <div class="subMnList">
                  <a><span class="subMn" :class="{ active : (path=='/media')}" @click="goPath('/media')">언론보도</span></a>
                  <a href="#video"><span class="subMn" :class="{ active : path=='/media#video'}" @click="goPath('/media','#video')">홍보영상</span></a>
                  <a href="https://mzktcloud-techblog.oopy.io/" target="_blank"><span class="subMn" :class="{ active : path=='/media#blog'}">기술 블로그</span></a>
                </div>
              </li>
              <li :class="{ active : path.match(/contact.*/)}">
                <a @click="goPath('/contact')">문의하기</a>
                <div class="subMnList">
                  <!-- <a><span class="subMn" :class="{ active : path=='/contact'}" @click="goPath('/contact')">자료실</span></a> -->
                  <!-- <a href="#notice"><span class="subMn" :class="{ active : path=='/contact#notice'}" @click="goPath('/contact','#notice')">공지사항</span></a> -->
                  <a href="#contactEmail"><span class="subMn" :class="{ active : path=='/contact#contactEmail'}" @click="goPath('/contact','#contactEmail')">문의하기</span></a>
                </div>
              </li>
            </ul>
          </div>
        </b-sidebar>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'TopMenu',
  data: function () {
    return {
      path: this.$route.path,
      showNavbar: true,
      scrollValue: 0
    }
  },
    props: [
    ],
  watch: {
   path(){}
  },
  computed: {
    tabItem: {
      set (tabItem) {
        this.$router.replace({ query: { ...this.$route.query, tabItem } })
      }
    }
  },
  methods: {
    gotoPage (selectedPage) {
      if(this.$route.path!==selectedPage)
        this.$router.push({
          name: selectedPage
        }).catch(()=>{})
      this.isActive= this.$route.name
    },
    goPath(selectedPath, hash){
      this.$router.push({
        path: selectedPath,
        hash: hash
      })
      if(hash!=undefined) this.path = selectedPath+hash
      else this.path = selectedPath
    },
    goMain(){
      this.path = '/'
      this.isActive = this.$route.name
      this.$router.push({name: 'MainPage'})
    },
    subActivate () {
      this.subMenu = !this.subMenu
      return this.subMenu
    },
    subActivate2 () {
      this.sampleSubMenu = !this.sampleSubMenu
      return this.sampleSubMenu
    },
    menuControl(boolean) {
      this.$parent.show(boolean)
    },

    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      this.showNavbar = currentScrollPosition < 60
    },
    scrollFix: function(hashbang)
    {
      location.hash = hashbang;
    }

  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
    setTimeout(() => this.scrollFix(this.$route.hash), 1);
    console.log(this.selectedKeys)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>
