<template>
  <div class="contents mediaPage reportPage">
    <div class="topVisual">
      <div class="container">
        <h4>미디어</h4>
        <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
      </div>
      <div class="location">
        <div class="container">
          <i class="fa-solid fa-house" @click="goMain()"></i><i class="fa-solid fa-chevron-right"></i>
          <a href="../media"><span class="txt">미디어</span></a><i class="fa-solid fa-chevron-right"></i>
          <a href="../media#report"><span class="txt">언론보도</span></a>
        </div>
      </div>
    </div>
      
    <section class="report" id="report">
      <div class="container">
        <div class="titArea">
          <h2>언론보도</h2>
          <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
        </div>
        <div class="conArea">
          <!-- contents 상세 start -->
          <div id="detail_001" class="detail">
            <div class="topWrap">
              <h4 class="tit">공공클라우드 협업구조란</h4>
              <span class="name">관리자</span>
              <span class="date">2023. 08. 30</span>
            </div>
            <div class="conWrap">
              <div class="origin"><a href="https://www.etnews.com/20230830000190" target="_blank">원문보기</a></div>
              <div class="conImg">
                <img src="../assets/media_report_04.jpg">
              </div>
              <span class="conTxt">
                성공 사례로 보는 공공 클라우드 '메이드 인 코리아 2023' 콘퍼런스가 30일 서울 양재동 엘타워에서 열렸다. 참석자들이 메가존 클라우드 부스에서 '공공클라우드 협업구조'에 대해 설명을 듣고 있다.
              </span>
            </div>
          </div>
          <!-- contents 상세 end -->

          <div class="bottomWrap">
            <a href="../media"><button class="btnList"><i class="fa-solid fa-chevron-left"></i>목록으로</button></a>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>


<script>
export default {
  methods: {
    goMain(){
      this.$router.push({name: 'MainPage'})
    },
  }
}
</script>


