<template>
    <div class="contents contactPage">
        <div class="topVisual contactBg">
          <div class="container">
            <h4>문의하기</h4>
            <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
          </div>
          <div class="location">
            <div class="container">
              <i class="fa-solid fa-house" @click="goMain()"></i><i class="fa-solid fa-chevron-right"></i>
              <a href="../contact"><span class="txt">문의하기</span></a>
            </div>
          </div>
        </div>
        <!-- <section class="reference" id="reference">
          <div class="container">
            <div class="titArea">
              <h2>자료실</h2>
              <h5>메가존클라우드의 유용한 자료를 확인해보세요.</h5>
            </div>
            <div class="conArea">
              <span class="download">회사소개서 다운로드 <i class="fa-solid fa-download"></i></span>
              <span class="download">브로슈어 다운로드 <i class="fa-solid fa-download"></i></span>
              <span class="download">회사소개서 다운로드_03 <i class="fa-solid fa-download"></i></span>
              <span class="download">회사소개서 다운로드_04 <i class="fa-solid fa-download"></i></span>
              <span class="download">회사소개서 다운로드_05 <i class="fa-solid fa-download"></i></span>
              <span class="download">회사소개서 다운로드_06 <i class="fa-solid fa-download"></i></span>
            </div>
          </div>
        </section> -->
        <section class="notice" id="notice" style="display: none;">
          <div class="container">
            <div class="titArea">
              <h2>공지사항</h2>
              <h5>새로운 소식을 알려드립니다. </h5>
            </div>
            <div class="conArea">
              <div id="noticeTable" class="contents table">
                <table class="noticeTable">
                    <caption></caption>
                  <colgroup>
                    <col width="10%">
                    <col width="50%">
                    <col width="20%">
                    <col width="20%" class="m_none">
                  </colgroup>
                  <thead>
                    <tr>
                      <th width="10%">번호</th>
                      <th width="50%">제목</th>
                      <th width="20%" class="m_none">작성자</th>
                      <th width="20%">게시일</th>
                    </tr>
                  </thead>
                  <tbody id='page1' class="pageBody" style="display: none;">
                    <tr><td>21</td><td class="td_detail" id="td_detail_021"><a onclick="goDetailNews('detail_021');" href="#news"><span class="category">뉴스</span>동서대학교-이테크시스템 전략적 MOU체결</a></td><td class="m_none">관리자</td><td>2022-09-14</td></tr>
                    <tr><td>20</td><td class="td_detail" id="td_detail_020"><a onclick="goDetailNews('detail_020');" href="#news"><span class="category">세미나</span>학교를 위한 AWS 기반의 가상 실습실과 인공지능 플랫폼 세미나</a></td><td class="m_none">관리자</td><td>2022-09-06</td></tr>
                    <tr><td>19</td><td class="td_detail" id="td_detail_019"><a onclick="goDetailNews('detail_019');" href="#news"><span class="category">세미나</span>모두를 위한 AWS 데이터 분석 실습 세미나</a></td><td class="m_none">관리자</td><td>2022-09-03</td></tr>
                    <tr><td>18</td><td class="td_detail" id="td_detail_018"><a onclick="goDetailNews('detail_018');" href="#news"><span class="category">프로모션</span>메가존클라우드에서 대박 게임 런칭하자!</a></td><td class="m_none">관리자</td><td>2022-09-01</td></tr>
                    <tr><td>17</td><td class="td_detail" id="td_detail_017"><a onclick="goDetailNews('detail_017');" href="#news"><span class="category">뉴스</span>퀸텟시스템즈-메가존클라우드 전략적 MOU체결  </a></td><td class="m_none">관리자</td><td>2022-08-23</td></tr>
                    <tr><td>16</td><td class="td_detail" id="td_detail_016"><a onclick="goDetailNews('detail_016');" href="#news"><span class="category">교육</span>제주테크노파크를 위한 AWS General Immersion Day</a></td><td class="m_none">관리자</td><td>2022-07-20</td></tr>
                    <tr><td>15</td><td class="td_detail" id="td_detail_015"><a onclick="goDetailNews('detail_015');" href="#news"><span class="category">세미나</span>Healthcare와 AWS의 두근거리는 만남</a></td><td class="m_none">관리자</td><td>2022-07-10</td></tr>
                    <tr><td>14</td><td class="td_detail" id="td_detail_014"><a onclick="goDetailNews('detail_014');" href="#news"><span class="category">세미나</span>개발자를 위한 AWS A to Z : 개발부터 배포까지</a></td><td class="m_none">관리자</td><td>2022-07-09</td></tr>
                    <tr><td>13</td><td class="td_detail" id="td_detail_013"><a onclick="goDetailNews('detail_013');" href="#news"><span class="category">세미나</span>핀테크 클라우드 망분리 관점에서 보는 아키텍쳐 파헤치기</a></td><td class="m_none">관리자</td><td>2022-06-30</td></tr>
                    <tr><td>12</td><td class="td_detail" id="td_detail_012"><a onclick="goDetailNews('detail_012');" href="#news"><span class="category">교육</span>AWS General Immersion Day for Starter</a></td><td class="m_none">관리자</td><td>2022-06-04</td></tr>
                  </tbody>
                  <tbody id='page2' class="pageBody" style="display:;">
                    <tr><td>10</td><td class="td_detail" id="td_detail_010"><a onclick="goDetailNews('detail_010');" href="#news">AWS Partner Summit Online Korea 2022</a></td><td class="m_none">관리자</td><td>2022-05-03</td></tr>
                    <tr><td>9</td><td class="td_detail" id="td_detail_009"><a onclick="goDetailNews('detail_009');" href="#news">AWS Summit Online Korea 2022</a></td><td class="m_none">관리자</td><td>2022-05-03</td></tr>
                    <tr><td>8</td><td class="td_detail" id="td_detail_008"><a onclick="goDetailNews('detail_008');" href="#news">대 코로나 시대, AWS 보안 백신 프로젝트!</a></td><td class="m_none">관리자</td><td>2022-05-03</td></tr>
                    <tr><td>7</td><td class="td_detail" id="td_detail_007"><a onclick="goDetailNews('detail_007');" href="#news">AWS DB Service로 쉽고 빠른 Migration</a></td><td class="m_none">관리자</td><td>2022-05-03</td></tr>
                    <tr><td>6</td><td class="td_detail" id="td_detail_006"><a onclick="goDetailNews('detail_006');" href="#news">성공적인 비즈니스를 위한 SECURITY with AWS &amp; 메가존 클라우드</a></td><td class="m_none">관리자</td><td>2022-03-30</td></tr>
                    <tr><td>5</td><td class="td_detail" id="td_detail_005"><a onclick="goDetailNews('detail_005');" href="#news">동양미래대학교 &amp; 메가존클라우드 마이스터대 시범운영 사업 추진을 위한 MOU 체결</a></td><td class="m_none">관리자</td><td>2022-03-15</td></tr>
                    <tr><td>4</td><td class="td_detail" id="td_detail_004"><a onclick="goDetailNews('detail_004');" href="#news">광주AI창업캠프 입주사를 위한 AWS Immersion Day with Cloud</a></td><td class="m_none">관리자</td><td>2022-03-04</td></tr>
                    <tr><td>3</td><td class="td_detail" id="td_detail_003"><a onclick="goDetailNews('detail_003');" href="#news">메가존클라우드 빌링 솔루션 ‘Billing-On’ 오픈</a></td><td class="m_none">관리자</td><td>2022-03-04</td></tr>
                    <tr><td>2</td><td class="td_detail" id="td_detail_002"><a onclick="goDetailNews('detail_002');" href="#news">광주형 인공지능 비즈니스 기반 조성을 위한 광주광역시 &amp; 메가존클라우드 MOU 체결</a></td><td class="m_none">관리자</td><td>2022-03-04</td></tr>
                    <tr><td>1</td><td class="td_detail" id="td_detail_001"><a onclick="goDetailNews('detail_001');" href="../notice">메가존클라우드, KT클라우드 최고등급 '플래티넘 파트너' 선정</a></td><td class="m_none">관리자</td><td>2022-03-04</td></tr>
                  </tbody>
                  <tbody id='page3' class="pageBody" style="display:none;">
                    <tr><td>1</td><td class="td_detail" id="td_detail_001"><a onclick="goDetailNews('detail_001');" href="#news"><span class="category">교육</span>Early Startup General Immersion Day AWS &amp; Youngwoo Digital</a></td><td class="m_none">관리자</td><td>2022-03-04</td></tr>
                  </tbody>
                  </table>

                  <div id="paging" class="page">
                    <ol>
                      <li id="pageNum_1" class="pageNumber select" @click="selectPageNumber(1);"><a>1</a></li>
                      <li id="pageNum_2" class="pageNumber "       @click="selectPageNumber(2);"><a>2</a></li>
                      <li id="pageNum_3" class="pageNumber "       @click="selectPageNumber(3);"><a>3</a></li>
                    </ol>
                  </div>
              </div>
            </div>
          </div>
        </section>

        <section class="contact" id="contactEmail">
          <div class="container">
            <div class="titArea">
              <h2>문의하기</h2>
              <h5>궁금하신 내용을 남겨주시면, 담당자가 빠른 시간 안에 연락 드리겠습니다.</h5>
            </div>

            <div role="form" class="wpcf7" id="wpcf7-f71129-p71126-o1" lang="en-US" dir="ltr">
            <div class="screen-reader-response"><p role="status" aria-live="polite" aria-atomic="true"></p> <ul></ul></div>
            <form id="registerform" class="wpcf7-form init" novalidate="novalidate" data-status="init" @submit="onSubmit">
              <div style="display: none;">
                <input type="hidden" name="_wpcf7" value="71129">
                <input type="hidden" name="_wpcf7_version" value="5.5.3">
                <input type="hidden" name="_wpcf7_locale" value="en_US">
                <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f71129-p71126-o1">
                <input type="hidden" name="_wpcf7_container_post" value="71126">
                <input type="hidden" name="_wpcf7_posted_data_hash" value="">
                <input type="hidden" name="_wpcf7cf_hidden_group_fields" value="[]">
                <input type="hidden" name="_wpcf7cf_hidden_groups" value="[]">
                <input type="hidden" name="_wpcf7cf_visible_groups" value="[]">
                <input type="hidden" name="_wpcf7cf_repeaters" value="[]">
                <input type="hidden" name="_wpcf7cf_steps" value="{}">
                <input type="hidden" name="_wpcf7cf_options" value="{&quot;form_id&quot;:71129,&quot;conditions&quot;:[{&quot;then_field&quot;:&quot;-1&quot;,&quot;and_rules&quot;:[{&quot;if_field&quot;:&quot;-1&quot;,&quot;operator&quot;:&quot;equals&quot;,&quot;if_value&quot;:&quot;&quot;}]}],&quot;settings&quot;:{&quot;animation&quot;:&quot;yes&quot;,&quot;animation_intime&quot;:200,&quot;animation_outtime&quot;:200,&quot;conditions_ui&quot;:&quot;normal&quot;,&quot;notice_dismissed&quot;:false,&quot;notice_dismissed_update-cf7-5.4&quot;:true,&quot;notice_dismissed_install-cf7&quot;:true}}">
              </div>

              <ul class="form-wrap">
                <li class="formLi">
                  <label><em>성 [필수]</em><span class="wpcf7-form-control-wrap last-name"><input type="text" name="last-name" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="성 (필수)"></span></label>
                </li>
                <li class="formLi">
                  <label><em>이름 [필수]</em><span class="wpcf7-form-control-wrap first-name"><input type="text" name="first-name" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="이름 (필수)"></span></label>
                </li>
                <li class="formLi">
                  <label><em>회사명 [필수]</em><span class="wpcf7-form-control-wrap your-company"><input type="text" name="your-company" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="회사명 (필수)"></span></label>
                </li>
                <li class="formLi">
                  <label><em>직급 [필수]</em><span class="wpcf7-form-control-wrap your-position"><input type="text" name="your-position" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" placeholder="직급 (필수)"></span></label>
                </li>
                <li class="formLi">
                  <label><em>휴대폰 [필수]</em><span class="wpcf7-form-control-wrap your-phone"><input type="tel" name="your-phone" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel" aria-required="true" aria-invalid="false" placeholder="'-' 를 제외하고 입력해 주세요."></span></label>
                </li>
                <li class="formLi">
                  <label><em>이메일 [필수]</em><span class="wpcf7-form-control-wrap your-email"><input type="email" name="your-email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" placeholder="회사 이메일 주소를 입력해 주세요."></span></label>
                </li>
                <li class="formLi fill">
                  <label><em>문의유형 [필수, 중복체크 가능]</em></label>
                  <span class="wpcf7-form-control-wrap inquiry-01">
                    <span class="wpcf7-form-control wpcf7-checkbox wpcf7-validates-as-required">
                      <span class="wpcf7-list-item first"><label><input type="checkbox" name="inquiry-01[]" value="kt cloud 인프라 신규 구축"><span class="wpcf7-list-item-label chkTxt">kt cloud 인프라 신규 구축</span></label></span>
                      <span class="wpcf7-list-item"><label><input type="checkbox" name="inquiry-01[]" value="KT IDC 코로케이션"><span class="wpcf7-list-item-label chkTxt">KT IDC 코로케이션</span></label></span>
                      <span class="wpcf7-list-item"><label><input type="checkbox" name="inquiry-01[]" value="kt cloud 전환"><span class="wpcf7-list-item-label chkTxt">kt cloud 전환</span></label></span>
                      <span class="wpcf7-list-item"><label><input type="checkbox" name="inquiry-01[]" value="KT IDC 전용회선"><span class="wpcf7-list-item-label chkTxt">KT IDC 전용회선</span></label></span>
                      <span class="wpcf7-list-item"><label><input type="checkbox" name="inquiry-01[]" value="kt cloud 인프라 관리"><span class="wpcf7-list-item-label chkTxt">kt cloud 인프라 관리</span></label></span>
                      <span class="wpcf7-list-item last"><label><input type="checkbox" name="inquiry-01[]" value="기타"><span class="wpcf7-list-item-label chkTxt">기타</span></label></span></span></span>
                </li>
                <li class="formLi formLi2 fill">
                  <label><em>문의내용 [선택]</em><span class="wpcf7-form-control-wrap inquiry-02"><textarea name="inquiry-02" cols="40" rows="10" maxlength="200" class="wpcf7-form-control wpcf7-textarea" aria-invalid="false" placeholder="문의 내용을 적어주세요."></textarea></span></label>
                </li>
                <li class="agreementLi fill">
                  <em><strong>개인정보의 수집 및 활용에 관한 동의 [필수]</strong></em>
                  <div class="agreement">
                    메가존 클라우드(“회사”)는 아래 내용에 따라 귀하의 정보를 수집 및 활용합니다. 다음의 내용을 숙지하시고 동의하는 경우 체크 박스에 표시해 주세요.<br><br>
                    1. 개인정보 수집자 : 메가존클라우드㈜<br><br>
                    2. 수집 받는 개인 정보<br>
                    [필수] 성, 이름, 회사명, 직급, 휴대폰, 이메일<br><br>
                    3. 수집/이용 목적<br>
                    - 상담 문의 처리, 이벤트 참여 확인 및 당첨자 안내<br>
                    <strong>- (마케팅 활용 동의 시) 관련 신규 상품 소개, 서비스 제공 및 이용, 행사 안내 (이메일 또는 전화, 문자 알림)</strong><br><br>
                    <strong>4. 보유 및 이용 기간 : 3년(단, 고객 동의 철회 시 지체없이 파기)</strong><br><br>
                    ※ 개인정보 이용 철회 방법<br>
                    - 안내 문자 등의 동의 철회를 이용하는 방법 : 이메일 수신 거부 링크 클릭 또는 안내 문자 내 수신거부 연락처를 통한 수신 거부 의사 통보<br>
                    - 개인정보 처리 상담 부서<br>
                    - 부서명: 마케팅그룹<br>
                    - 연락처: 02-2108-9171, event@megazone.com<br><br>
                    ※ 동의거부권 및 불이익<br>
                    귀하는 동의를 거절할 수 있는 권리를 보유하며, 동의를 거절하는 경우 상기 이용 목적에 명시된 서비스가 제공되지 아니합니다.
                  </div>
                </li>
                <li class="fill agree">
                  <label>
                    <span class="wpcf7-form-control-wrap privacy-agreement01">
                      <span class="wpcf7-form-control wpcf7-checkbox wpcf7-validates-as-required">
                        <span class="wpcf7-list-item first last">
                          <input type="checkbox" name="privacy-agreement01[]" value="(필수) 네, 위 내용을 확인하였으며 개인 정보 수집 및 활용에 동의합니다.">
                          <span class="wpcf7-list-item-label chktxt">(필수) 네, 위 내용을 확인하였으며 개인 정보 수집 및 활용에 동의합니다.</span>
                        </span>
                      </span>
                    </span>
                  </label>
                </li>
                <li class="fill agree">
                  <label><span class="wpcf7-form-control-wrap privacy-agreement02">
                    <span class="wpcf7-form-control wpcf7-checkbox">
                      <span class="wpcf7-list-item first last">
                        <input type="checkbox" name="privacy-agreement02[]" value="(선택) 네, 위 내용을 확인하였으며 회사가 신규서비스에 대한 홍보, 다양한 행사 안내에 참여를 위해 본인의 개인정보가 회사의 마케팅 목적으로 이용되는 것에 동의합니다.">
                        <span class="wpcf7-list-item-label chktxt">(선택) 네, 위 내용을 확인하였으며 회사가 신규서비스에 대한 홍보, 다양한 행사 안내에 참여를 위해 본인의 개인정보가 회사의 마케팅 목적으로 이용되는 것에 동의합니다.</span>
                      </span>
                    </span>
                  </span>
                </label>
                </li>
                <li class="agreementLi fill m_t_20">
                  <em><strong>개인정보의 제3자 제공에 관한 동의 [선택]</strong></em>
                  <div class="agreement">
                    회사는 다음과 같이 마케팅 목적으로 개인정보를 공유합니다.<br><br>
                    * 개인정보를 제공 받는자 : 주식회사 케이티클라우드(이하 “kt cloud”)<br><br>
                    * 제공받는 자의 정보 이용 목적 : kt cloud는 고객의 kt cloud 계정 생성 및 관리, kt cloud 의 서비스 제공, kt cloud 서비스 개선, kt cloud  서비스 홍보, 대금 결제, kt cloud 의 계정 또는 서비스에 관한 의사소통 및 고객 요청 대응을 위해 개인정보를 이용하게 됩니다.<br><br>
                    * 이용 또는 제공받는 개인 정보 항목<br>
                    - 이름, 회사명, 부서명, 직급, 휴대폰/회사 번호, 이메일 주소, 회사 우편번호 또는 주소<br><br>
                    * 개인정보의 보유 및 이용기간 :<br>
                    고객의 개인정보는 kt cloud 와의 비즈니스 관계가 유지되는 동안 그리고 kt cloud 의 법적 의무, 분쟁 해결 및 계약 시행에 필요한 기간 동안만 보유됩니다. 또한, kt cloud 은 kt cloud 의 데이터 보존 정책 및 관련 법률에 따라 고객 개인 정보를 삭제하며, 고객이 계정을 종료한 이후에는 kt cloud 은 적시에 개인 정보를 삭제하게 됩니다. 그러나, kt cloud 의 법적 의무, 분쟁 해결 및 계약 이행에 필요한 경우 또는 법률 규정에 따라 개인 정보를 보관해야 하는 경우에는, 그 목적을 위하여 필요한 기간 동안 해당 개인정보를 보유하게 됩니다.<br><br>
                    ※ 귀하는 수신된 커뮤니케이션 내용의 지침에 따라 언제든지 kt cloud 의 뉴스 및 할인행사 수신 구독을 취소할 수 있으며, 동의를 거절하는 경우 상기 이용 목적에 명시된 서비스가 제공되지 아니합니다.<br>
                    ※ kt cloud는 <a href="https://cloud.kt.com/privacy-policy/" target="_blank">kt cloud 개인정보처리방침</a>에 따라 귀하의 정보를 처리합니다.
                  </div>
                </li>
                <li class="fill agree">
                  <label><span class="wpcf7-form-control-wrap privacy-agreement03">
                    <span class="wpcf7-form-control wpcf7-checkbox">
                      <span class="wpcf7-list-item first last">
                        <input type="checkbox" name="privacy-agreement03[]" value="(선택) 예, kt cloud 로 부터 kt cloud 서비스에 대한 최신 뉴스 및 관련 제안을 이메일이나 우편 또는 전화로 받아보고 싶습니다.">
                        <span class="wpcf7-list-item-label chktxt">(선택) 예, kt cloud 로 부터 kt cloud 서비스에 대한 최신 뉴스 및 관련 제안을 이메일이나 우편 또는 전화로 받아보고 싶습니다.</span>
                      </span>
                    </span>
                  </span>
                </label>
                </li>
              </ul>
              <div class="submit-wrap"><input type="submit" id="submitBtn" :value="this.posting ? '전송중..' : '상담신청'" :disabled="this.posting" class="wpcf7-form-control has-spinner wpcf7-submit btn m_t_50"><span class="wpcf7-spinner" v-if="this.posting"></span></div>
              <div class="wpcf7-response-output" aria-hidden="true"></div><input type="hidden" name="vx_width" value="3440"><input type="hidden" name="vx_height" value="1440"><input type="hidden" name="vx_url" value="/contact#contactEmail">
            </form>
            </div>



            <!-- <iframe class="contactEmail" frameborder="0" width="100%" height="1540px" id="#my-iframe-id" src="https://go.megazone.com/l/953343/2022-11-03/3xh47" overflow="auto"></iframe> -->

            <div class="conArea" style="display: none;">
              <dl>
                <dt>회사명</dt>
                <dd><input type="text" id="name" name="name" required  minlength="1" maxlength="50" placeholder="회사명을 입력하세요"></dd>
              </dl>
              <dl>
                <dt>이름</dt>
                <dd><input type="text" id="name" name="name" required  minlength="1" maxlength="50" placeholder="이름을 입력하세요"></dd>
              </dl>
              <dl>
                <dt>직책/직급</dt>
                <dd><input type="text" id="name" name="name" required  minlength="1" maxlength="50" placeholder="직책/직급을 입력하세요"></dd>
              </dl>
              <dl>
                <dt>연락처</dt>
                <dd><input type="text" id="name" name="name" required  minlength="11" maxlength="11" placeholder="연락처를 입력하세요"></dd>
              </dl>
              <dl>
                <dt>이메일</dt>
                <dd><input type="text" id="mail" name="mail" required  minlength="1" maxlength="100" placeholder="이메일을 입력하세요"></dd>
              </dl>
              <dl>
                <dt>문의유형</dt>
                <dd class="selectWrap">
                  <span class="selectTit">유형을 선택하세요<i class="fa-solid fa-sort-down"></i></span>
                  <div class="select">
                    <ul>
                      <li>111</li>
                      <li>222</li>
                      <li>333</li>
                    </ul>
                  </div>
                </dd>
              </dl>
              <dl class="askFrom">
                <dt>문의내용</dt>
                <dd><textarea name="opinion" cols="20" rows="6" placeholder="문의내용을 입력하세요"></textarea></dd>
              </dl>

              <div class="agreementWrap">
                <h4>개인정보 수집/이용 동의 [필수]</h4>
                <div class="formTxt"><input type="checkbox"><span class="txt">(필수) 네, 위 내용을 확인하였으며 개인 정보 수집 및 활용에 동의합니다.</span></div>
                <h4>마케팅 활용 동의 [선택]</h4>
                <div class="formTxt"><input type="checkbox"><span class="txt">(선택) 네, 위 내용을 확인하였으며, 회사가 신규서비스에 대한 뉴스레터, 홍보 및 다양한 행사 안내를 위해 본인의 개인정보를 회사의 마케팅 목적으로 이용되는 것에 동의합니다.</span></div>
                <span class="chk_textbox">
                  메가존 클라우드(“회사”)는 아래 내용에 따라 귀하의 정보를 수집 및 활용합니다. 다음의 내용을 숙지하시고 동의하는 경우 체크 박스에 표시해 주세요.<br><br>
                  1. 개인정보 수집자 : 메가존클라우드㈜<br><br>
                  2. 수집 받는 개인 정보<br>
                  [필수] 회사명, 성함, 직책, 연락처, 이메일주소<br><br>
                  3. 수집/이용 목적<br>
                  - 상담 문의 처리, 이벤트 참여 확인 및 당첨자 안내<br>
                  <strong>- (마케팅 활용 동의 시) 관련 신규 상품 소개, 서비스 제공 및 이용, 행사 안내 (이메일 또는 전화, 문자 알림)</strong><br><br>
                  <strong>4. 보유 및 이용 기간 : 수집일로부터 3년(단, 고객 동의 철회 시 지체없이 파기)</strong><br><br>
                  ※ 개인정보 이용 철회 방법<br>
                  - 안내 문자 등의 동의 철회를 이용하는 방법 : 이메일 수신 거부 링크 클릭 또는 안내 문자 내 수신거부 연락처를 통한 수신 거부 의사 통보<br>
                  - 개인정보 처리 상담 부서<br>
                  - 부서명: 마케팅그룹<br>
                  - 연락처: 02-2108-9171,&nbsp;event@megazone.com<br><br>
                  <strong>※ 동의거부권 및 불이익</strong><br>
                  귀하는 동의를 거절할 수 있는 권리를 보유하며, 동의를 거절하는 경우 상기 이용 목적에 명시된 서비스가 제공되지 아니합니다.
                </span>
              </div>
              <div class="bottomBtnArea">
                <button class="btn">문의하기</button>
              </div>
            </div>
          </div>
        </section>
    </div>
</template>


<script>
// import jQuery from 'jquery'
import axios from 'axios'
import router from '@/router'

  export default {
    data () {
      return {
        page: 1,
        note_id: null,
        posting: false
      }
    },
    watch:{
      posting(){}
    },
    methods: {
    goMain(){
      this.$router.push({name: 'MainPage'})
    },
    selectPageNumber(page){
      this.page = page
    },
    async onSubmit(event){
      event.preventDefault();

      this.posting = true
      let myForm = document.getElementById('registerform'); 
      let formData = new FormData(myForm);


      let response = null
      try {
        response = await axios.post(`https://www.megazone.com/wp-json/contact-form-7/v1/contact-forms/71129/feedback`, formData, {
          headers: { 'Content-Type': 'multipart/form-data'}
        })
      } catch (errorData) {
        this.posting = false
        throw console.log('오류' + errorData)
      }
      this.posting = false
      const [Response] = await Promise.all([response.data])
      console.log(Response.message)

      if(Response.status!="mail_sent") {
      alert(Response.message);
      }
      else if(Response.status=="mail_sent") {
        alert( "신청해 주셔서 감사합니다." );
        router.go(0)
      }
    }
  }
  }
</script>



<!-- <script>
  document.addEventListener( 'wpcf7mailsent', function( event ) {
    alert( "신청해 주셔서 감사합니다." );
  }, false );
  var disableSubmit = false;
  jQuery('input.wpcf7-submit[type="submit"]').click(function() {
      jQuery(':input[type="submit"]').attr('value',"전송중...")
      if (disableSubmit == true) {
          return false;
      }
      disableSubmit = true;
      return true;
  })  
  var wpcf7Elm = document.querySelector( '.wpcf7' );
  wpcf7Elm.addEventListener( 'wpcf7submit', function( event ) {
      jQuery(':input[type="submit"]').attr('value',"상담 신청하기")
      disableSubmit = false;
  }, false );
</script> -->

