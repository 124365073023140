<template>
  <div class="contents mediaPage reportPage">
    <div class="topVisual">
      <div class="container">
        <h4>미디어</h4>
        <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
      </div>
      <div class="location">
        <div class="container">
          <i class="fa-solid fa-house" @click="goMain()"></i><i class="fa-solid fa-chevron-right"></i>
          <a href="../media"><span class="txt">미디어</span></a><i class="fa-solid fa-chevron-right"></i>
          <a href="../media#report"><span class="txt">언론보도</span></a>
        </div>
      </div>
    </div>
      
    <section class="report" id="report">
      <div class="container">
        <div class="titArea">
          <h2>언론보도</h2>
          <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
        </div>
        <div class="conArea">
          <!-- contents 상세 start -->
          <div id="detail_001" class="detail">
            <div class="topWrap">
              <h4 class="tit">메가존 MSP 2.0으로 3년 내 K-클라우드 1위 목표</h4>
              <span class="name">관리자</span>
              <span class="date">2023. 09. 27</span>
            </div>
            <div class="conWrap">
              <div class="origin"><a href="https://zdnet.co.kr/view/?no=20230927141038" target="_blank">원문보기</a></div>
              <span class="conTxt">
                “많은 기업들이 클라우드 도입을 검토하고 있지만, 아직도 많은 곳은 어려움을 겪고 명확한 방향을 정하지 못하고 있습니다. 메가존클라우드 MSP 2.0은 고객 요구 사항에 맞는 서비스 패키지를 제공해 향후 3년 안에 국내 K-클라우드 점유율 1위를 달성할 것입니다.”
                <br><br>
                안찬호 메가존클라우드 K-클라우드센터 리더는 27일 서울 코엑스에서 열린 2023 대한민국 디지털 미래혁신대전 중 ‘멀티 클라우드 컨퍼런스’에서 이같이 말했다. 그는 이날 ‘K-클라우드 차세대 관리 서비스’를 주제로 발표했다.
                <br><br>
                안 리더는 먼저 국내 클라우드 시장 현황에 대해 설명했다. 안 리더는 “클라우드 시장은 인프라·논인프라 모두 지속적으로 성장하고 있다”며 “클라우드는 올해 기준 IT 인프라 시장의 약 55% 이상을 점유하고 있으며 25년에는 60% 이상 점유할 것으로 예상되고 있다”고 전했다.
                <br><br>
                이어 “특히 클라우드 서비스 전환 가속화에 따라 단순 전환을 넘어 서비스 상품 확장이 두드러지고 있다”며 “시장이 커지는 것은 클라우드 서비스를 관리하는 서비스, 즉 MSP 시장도 함께 성장하는 것을 의미한다”고 덧붙였다.신하고 글로벌 진출에 성공할 수 있도록 조합이 함께 노력할 것”이라며 “앞으로도 민간 시장의 기업, 공공 시장의 기관들을 대상으로 'MADE IN KOREA' 컨퍼런스를 진행할 계획”이라고 전했다.<br>
              </span>
              <div class="conImg">
                <img src="../assets/media_report_06_1.jpg">
              </div>
              <span class="conTxt">
                행정안전부는 앞서 행정 공공기관 정보시스템 전환 추진 계획에서 2025년까지 1만9개 시스템을 퍼블릭 클라우드로 전환할 계획을 밝힌 바 있다. 여기에 적게는 매년 1천200억원부터 최대 3천억원까지 총 8천700억원의 예산을 투입할 계획이다. 정부의 클라우드 전환에 대한 의지를 확인할 수 있는 내용이라고 안 리더는 설명했다.
                <br><br>
                클라우드 서비스 시장이 성장하면서 고객 요구사항도 확장되는 추세다. 클라우드 구축과 전환은 클라우드 운영관리 서비스 전문기업(MSP)를 통해 이뤄진다.
                <br><br>
                안찬호 리더는 “클라우드 서비스 도입 보편화로 인해 고객 입장에서는 새로운 사업과 시장을 진출하기 위한 방향으로 전환 중”이라며 “기존 인프라 중심의 구축, 운영 범위에서 이제는 하드웨어 장애, 기술, 비용 최적화 등 추가적인 서비스를 기존 MSP 서비스와 통합해 제공받기를 희망하고 있다”고 부연했다.
                <br><br>
                메가존클라우드 K-클라우드 센터가 추구하는 MSP 2.0 전략은 5가지 핵심 키워드를 중심으로 구성됐다. 공공·금융 중심, 확장된 기술범위의 클라우드 풀 스택, 멀티·하이브리드 클라우드 구성, 컨테이너 MSA 기반의 클라우드 네이티브, 24X7 즉시 조치 가능한 기술 지원이다.
                <br><br>
                안 리더는 먼저 “최근 공공·금융 클라우드 전환에 대해 고객의 요구사항을 파악하면 4가지 정도를 핵심 요구사항으로 볼 수 있다”며 “높은 등급의 장애 예방, 풀 스택 서비스, 일일점검 보고, 국정원 기준 보안관리가 요구된다”고 전했다.
              </span>
              <div class="conImg">
                <img src="../assets/media_report_06_2.jpg">
              </div>
              <span class="conTxt">
                풀 스택 전략에 대해서는 “콘솔 레벨에서 서버 접근, OSS, PaaS, 어플리케이션 영역까지 운영 관리를 요청하고 있다”며 “하드웨어부터 어플리케이션까지 다양한 요구 범위를 능동적으로 이끌고자 한다”고 말했다.
                <br><br>
                멀티·하이브리드 전략은 기존 시스템을 어느 정도 유지하면서 새로운 클라우드를 도입하는 방향 등을 포함한다. 이를 위해 MSP 2.0은 자체 통합 클라우드 관리 플랫폼을 적용하고 있다. 안 리더는 약 10년 간 MSP 노하우를 담은 플랫폼 ‘스페이스 원(Space One)’도 소개했다.
                <br><br>
                클라우드 네이티브는 고객 맞춤형 컨설팅으로 컨테이너 기반 최적의 퍼블릭 클라우드 설계를 목표로 하고 있다. MSA 설계, PaaS, 모니터링 3개 영역에서 서비스를 제공한다.
                <br><br>
                마지막으로 24X7 기술 지원은 상시 조치 가능한 영역을 의미한다. 기존 MSP 1.0 영역에서는 24X7 모니터링과 전파가 주요 과업이었다면, 이제 상시 대응 가능한 엔지니어 체계를 구축하고 이들이 상주하는 다양한 거점으로 고객에게 직접 대응할 예정이라고 안 리더는 소개했다. 현재 24X7 센터는 서울과 부산에 구축됐으며 내년에는 과천에도 설치될 예정이다.
                <br><br>
                메가존클라우드는 지난해 유니콘 기업(기업가치 1조원 이상)으로 성장한 데 이어 MSP 2.0으로 한 단계 더 도약한다는 방침이다. 지난 5년간 MSP 1.0 기반 성장으로 향후 MSP 2.0 구축과 확장에 주력한다.
                <br><br>
                메가존클라우드 K-클라우드센터는 MSP 2.0이 자체 솔루션뿐만 아니라 다양한 파트너와 파트너십을 맺고 패키지 형태로 서비스를 제공하는 것을 목표로 한다. 향후 고객 만족과 요구 사항에 맞게 파트너십을 구축해 나가겠다는 계획이다. 이를 통해 향후 3년 안에 국내 K-클라우드 점유율 1위를 달성하겠다고 안 리더는 강조했다.
              </span>
            </div>
          </div>
          <!-- contents 상세 end -->

          <div class="bottomWrap">
            <a href="../media"><button class="btnList"><i class="fa-solid fa-chevron-left"></i>목록으로</button></a>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>


<script>
export default {
  methods: {
    goMain(){
      this.$router.push({name: 'MainPage'})
    },
  }
}
</script>


