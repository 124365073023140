<template>
  <div id="root">
    <top-menu/>
    <router-view/>
    <a href="#top"><div class="topIcon"><i class="fa-solid fa-arrow-up"></i></div></a>
    <a href="../contact#contactEmail"><div class="contactIcon"><i class="fa-solid fa-question"></i></div></a>
    <footer-component/>
  </div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
import FooterComponent from '@/components/FooterComponent.vue'
export default {
  name: 'App',
  components: {TopMenu, FooterComponent}
}
</script>