import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify'
import { BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './fontawesome-free-6.1.1-web/css/all.css'
import "./style/privacy.css";
import './style/layout.css'
import './style/default.css'
import './style/main.css'
import './style/sub.css'
import AOS from 'aos';
import "aos/dist/aos.css";

// import './style/style.css'
// import './style/modal.css'
// import './style/login.css'
// import './style/contents.css'


Vue.use(Vuetify)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false

new Vue({
  created() {
    AOS.init();
},
  router,
  vuetify : new Vuetify(),
  render: h => h(App),
}).$mount('#app')
