<template>
    <div class="contents mediaPage">
        <div class="topVisual">
          <div class="container">
            <h4>미디어</h4>
            <h5>메가존클라우드의 소식과 영상자료를 확인하세요.</h5>
          </div>

          <div class="location">
            <div class="container">
              <i class="fa-solid fa-house" @click="goMain()"></i><i class="fa-solid fa-chevron-right"></i><a href="../media"><span class="txt">미디어</span></a>
            </div>
          </div>

        </div>
        <section class="report" id="report">
          <div class="container">
            <div class="titArea">
              <h2>언론보도</h2>
              <h5>메가존클라우드의 최근 소식을 확인해보세요.</h5>
            </div>
            <div class="conArea">
              <div class="list">
                <a class="part" href="../report06">
                  <div class="thumb report_thumb_06"></div>
                  <h4>메가존 MSP 2.0으로  <br>3년 내 K-클라우드 1위 목표</h4>
                  <span class="date">2023. 09. 27</span>
                </a>
                <a class="part" href="../report05">
                  <div class="thumb report_thumb_05"></div>
                  <h4>한국IoT융합사업협동조합, <br>'MADE IN KOREA 2023' 콘퍼런스 개최</h4>
                  <span class="date">2023. 08. 31</span>
                </a>
                <a class="part" href="../report04">
                  <div class="thumb report_thumb_04"></div>
                  <h4>공공클라우드 협업구조란</h4>
                  <span class="date">2023. 08. 30</span>
                </a>
                <a class="part" href="../report03">
                  <div class="thumb report_thumb_03"></div>
                  <h4>AI, Cloud, IDC 산업 관련 기관∙기업 관계자 한자리에...<br>‘kt cloud summit 2023’ 개최 DX 혁신 기술∙전략 공유</h4>
                  <span class="date">2023. 04. 18</span>
                </a>
                <a class="part" href="../report02">
                  <div class="thumb report_thumb_02"></div>
                  <h4>[단독] KT, 메가존클라우드에 1300억 투자</h4>
                  <span class="date">2022. 02. 15</span>
                </a>
                <a class="part" href="../report01">
                  <div class="thumb report_thumb_01"></div>
                  <h4>메가존클라우드,  <br>KT클라우드 최고등급 '플래티넘 파트너' 선정</h4>
                  <span class="date">2022. 10. 28</span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section class="video" id="video">
          <div class="container">
            <div class="titArea">
              <h2>홍보영상</h2>
              <h5>메가존클라우드의 영상자료를 확인해보세요.</h5>
            </div>
            <div class="conArea">
              <div class="list">
                <a class="part" href="../video05">
                  <div class="thumb video_thumb_05"></div>
                  <h4>[메이드인코리아 2023] 메가존클라우드 공공클라우드 협업구조 소개</h4>
                  <span class="date">2023. 09. 12</span>
                </a>
                <a class="part" href="../video04">
                  <div class="thumb video_thumb_04"></div>
                  <h4>kt cloud와 함께하는, Hyper Connectivity 사업협력 모델소개</h4>
                  <span class="date">2023. 08. 08</span>
                </a>
                <a class="part" href="../video03">
                  <div class="thumb video_thumb_03"></div>
                  <h4>[kt cloud: SUMMIT 2023] AI 자원의 한계를 뛰어넘는 Hybrid MLOps 플랫폼 - 메가존클라우드 강민주 그룹장</h4>
                  <span class="date">2023. 05. 16</span>
                </a>
                <a class="part" href="../video01">
                  <div class="thumb video_thumb_01"></div>
                  <h4>[대한민국 4차 산업혁명 페스티벌 2022] Multi Cloud Zone - 메가존클라우드 x kt cloud</h4>
                  <span class="date">2022. 12. 22</span>
                </a>
                <a class="part" href="../video02">
                  <div class="thumb video_thumb_02"></div>
                  <h4>[대한민국 4차 산업혁명 페스티벌 2022] 정보시스템 대상 클라우드 전환 우수사례_kt cloud Sales - 이준희 팀장</h4>
                  <span class="date">2022. 12. 22</span>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section class="blog" id="blog">
          <div class="container">
            <a href="https://mzktcloud-techblog.oopy.io/" target="_blank" class="blogWrap">
              <div class="conArea">
                <h3>메가존클라우드 <br>기술 블로그</h3>
                <h4>메가존클라우드의 kt cloud 기술 전문가가 <br>유용한 기술정보를 쉽게 알려드립니다.</h4>
                <a href="#" class="slideBtn">자세히보기 <i class="fa-solid fa-chevron-right"></i></a>
              </div>
            </a>
          </div>
        </section>
    </div>
</template>

<script>
export default {
  methods: {
    goMain(){
      this.$router.push({name: 'MainPage'})
    },
  }
}
</script>