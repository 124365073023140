<template>
    <div class="main">
        <section class="carousel">
          <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
          >
            <b-carousel-slide>
              <template #img>
                <img class="d-block img-fluid w-100 slideImg01" width="1024" height="480" src="../assets/mainVisual01.png" alt="slide image">
              </template>
              <div class="slideInner">
                <h5>클라우드 통합관리 No.1</h5>
                <h4>과거, 현재 <br>그리고 미래의 Cloud</h4>
                <h3>메가존클라우드가 <br>여정을 함께합니다</h3>
                <a href="#contactEmail" @click="goPath('/contact','#contactEmail')" class="slideBtn">자세히보기 <i class="fa-solid fa-chevron-right"></i></a>
              </div>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img class="d-block img-fluid w-100 slideImg02" width="1024" height="480" src="../assets/mainVisual02.png" alt="slide image">
              </template>
              <div class="slideInner">
                <h5>디지털플랫폼정부 필수요소</h5>
                <h4>KT 공공전용 <br>클라우드 전환 사업</h4>
                <h3>메가존클라우드와 <br>상의하세요</h3>
                <a @click="goPath('/service?tabItem=publicCloud')" class="slideBtn">자세히보기 <i class="fa-solid fa-chevron-right"></i></a>
              </div>
            </b-carousel-slide>
            <!-- <b-carousel-slide>
              <template #img>
                <img class="d-block img-fluid w-100 slideImg02" width="1024" height="480" src="../assets/mainVisual03.png" alt="slide image">
              </template>
              <div class="slideInner">
                <h5>KT DR as a Service</h5>
                <h4>On-Demand로 <br>클라우드 기반의</h4>
                <h3>재해 복구서비스를 <br>경험하세요</h3>
                <a @click="goPath('/service?tabItem=dr')" class="slideBtn">자세히보기 <i class="fa-solid fa-chevron-right"></i></a>
              </div>
            </b-carousel-slide> -->
            <b-carousel-slide>
              <template #img>
                <img class="d-block img-fluid w-100 slideImg03" width="1024" height="480" src="../assets/mainVisual04.png" alt="slide image">
              </template>
              <div class="slideInner">
                <h5>언론보도</h5>
                <h4>메가존클라우드, <br>kt cloud</h4>
                <h3>‘최고 등급 파트너로 선정’</h3>
                <a href="#report" @click="goPath('/report01')" class="slideBtn">자세히보기 <i class="fa-solid fa-chevron-right"></i></a>
              </div>
            </b-carousel-slide>
          </b-carousel> 
        </section>

        <section class="carousel m_carousel">
          <b-carousel
          id="carousel-1"
          v-model="slide"
          :interval="400000000"
          controls
          indicators
          background="#ababab"
          img-width="800"
          img-height="480"
          @sliding-start="onSlideStart"
          @sliding-end="onSlideEnd"
          >
            <b-carousel-slide>
              <template #img>
                <img class="d-block img-fluid w-100 slideImg01" width="800" height="480" src="../assets/m_mainVisual01.png" alt="slide image">
              </template>
              <div class="slideInner">
                <h4>과거, 현재 <br>그리고 미래의 Cloud</h4>
                <h3>메가존클라우드가 <br>여정을 함께합니다</h3>
                <a href="#contactEmail" @click="goPath('/contact','#contactEmail')" class="slideBtn">자세히보기 <i class="fa-solid fa-chevron-right"></i></a>
              </div>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img class="d-block img-fluid w-100 slideImg02" width="800" height="480" src="../assets/m_mainVisual02.png" alt="slide image">
              </template>
              <div class="slideInner">
                <h4>KT 공공전용 <br>클라우드 전환 사업</h4>
                <h3>메가존클라우드와 <br>상의하세요</h3>
                <a @click="goPath('/service?tabItem=publicCloud')" class="slideBtn">자세히보기 <i class="fa-solid fa-chevron-right"></i></a>
              </div>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img class="d-block img-fluid w-100 slideImg03" width="800" height="480" src="../assets/m_mainVisual03.png" alt="slide image">
              </template>
              <div class="slideInner">
                <h4>On-Demand로 <br>클라우드 기반의</h4>
                <h3>재해 복구서비스를 <br>경험하세요</h3>
                <a @click="goPath('/service?tabItem=dr')" class="slideBtn">자세히보기 <i class="fa-solid fa-chevron-right"></i></a>
              </div>
            </b-carousel-slide>
            <b-carousel-slide>
              <template #img>
                <img class="d-block img-fluid w-100 slideImg03" width="800" height="480" src="../assets/m_mainVisual04.png" alt="slide image">
              </template>
              <div class="slideInner">
                <h4>메가존클라우드, <br>kt cloud</h4>
                <h3>최고 등급 <br>파트너로 선정</h3>
                <a href="#report" @click="goPath('/report01')" class="slideBtn">자세히보기 <i class="fa-solid fa-chevron-right"></i></a>
              </div>
            </b-carousel-slide>
          </b-carousel> 
        </section>

        <section class="service">
          <div class="container">
            <div class="titArea">
              <h2 data-aos="fade-up" data-aos-duration="700">서비스</h2>
              <h5 data-aos="fade-up" data-aos-duration="700">메가존클라우드의 매니지드 서비스와 함께 기업 및 공공에 더욱 특화된 kt cloud를 경험하세요. <span class="moreBtnR" @click="gotoPage('ServicePage')">더보기<i class="fa-solid fa-chevron-right"></i></span></h5>
            </div>
            <div class="conArea">
              <div class="why" href="#why" @click="goPath('/service','#why')">
                <div class="whyBox">
                    <span class="txt01" data-aos="fade-up" data-aos-duration="700">Why kt cloud on MEGAZONECLOUD?</span>
                    <span class="txt02" data-aos="fade-up" data-aos-duration="700">메가존클라우드, <br>성공적인 kt cloud 도입을 위한 필수 선택</span>
                    <span class="txt03" data-aos="fade-up" data-aos-duration="700">메가존클라우드는 국내 및 아시아 업계 1위 MSP 사업자이자 kt cloud 플래티넘 파트너사로서 고객사에 최적의 서비스와 솔루션을 제공합니다.</span>
                  </div>
              </div>
              <div class="serviceList">
                <router-link to="/service?tabItem=cloud" class="service02" data-aos="flip-down" data-aos-duration="1100">
                  <h4>기업전용 클라우드</h4>
                  <span class="txt">산업별 맞춤 클라우드 <br>통합 관리 서비스</span>
                  <i class="fa-solid fa-chevron-right arrLine"></i>
                </router-link>
                <router-link to="/service?tabItem=publicCloud" class="service01" data-aos="flip-down" data-aos-duration="1000">
                  <h4>공공전용 클라우드</h4>
                  <span class="txt">보안에 특화된  <br>공공 클라우드 서비스</span>
                  <i class="fa-solid fa-chevron-right arrLine"></i>
                </router-link>
                <router-link to="/service?tabItem=dr" class="service03" data-aos="flip-down" data-aos-duration="1200">
                  <h4>DR as a service</h4>
                  <span class="txt">국내 유일의 클라우드 기반 <br>무중단 DR 솔루션</span>
                  <i class="fa-solid fa-chevron-right arrLine"></i>
                </router-link>
              </div>
            </div>
          </div>
        </section>

        <section class="partner">
          <div class="container">
            <div class="titArea">
              <h2 data-aos="fade-up" data-aos-duration="700">고객사례</h2>
              <h5 data-aos="fade-up" data-aos-duration="700">다양한 분야의 고객사에 안정된 클라우드 통합 관리 서비스를 제공하고 있습니다. <span class="moreBtnR" @click="goPath('/partner')">더보기<i class="fa-solid fa-chevron-right"></i></span></h5>
            </div>
            <div class="conArea">
              <div class="partnerWrap">
                <a class="ci_01" href="../partner" data-aos="fade-up" data-aos-duration="1000"></a>
                <a class="ci_02" href="../partner" data-aos="fade-up" data-aos-duration="1000"></a>
                <a class="ci_03" href="../partner" data-aos="fade-up" data-aos-duration="1000"></a>
                <a class="ci_04" href="../partner" data-aos="fade-up" data-aos-duration="1000"></a>
                <a class="ci_05" href="../partner" data-aos="fade-up" data-aos-duration="1000"></a>
                <a class="ci_06" href="../partner" data-aos="fade-up" data-aos-duration="1000"></a>
                <a class="ci_07" href="../partner" data-aos="fade-up" data-aos-duration="1000"></a>
                <a class="ci_08" href="../partner" data-aos="fade-up" data-aos-duration="1000"></a>
                <a class="ci_09" href="../partner" data-aos="fade-up" data-aos-duration="1000"></a>
                <a class="ci_10" href="../partner" data-aos="fade-up" data-aos-duration="1000"></a>
                <a class="ci_11" href="../partner" data-aos="fade-up" data-aos-duration="1000"></a>
                <a class="ci_12" href="../partner" data-aos="fade-up" data-aos-duration="1000"></a>
                <a class="ci_13" href="../partner" data-aos="fade-up" data-aos-duration="1000"></a>
                <a class="ci_14" href="../partner" data-aos="fade-up" data-aos-duration="1000"></a>
                <a class="ci_15" href="../partner" data-aos="fade-up" data-aos-duration="1000"></a>
                <a class="ci_16" href="../partner" data-aos="fade-up" data-aos-duration="1000"></a>
              </div>
            </div>
          </div>
        </section>

        <section class="conCard">
          <div class="container">
            <div class="conCardList">
              <div class="part event">
                <h4 data-aos="fade-up">이벤트</h4>
                <a class="detail" data-aos="flip-up" data-aos-duration="600" @click="goPath('/event')">
                  <div class="cardImgBox">
                    <span class="cardImg card_01"></span>
                  </div>
                  <div class="txtWrap">
                    <span class="tit">메가존클라우드 이벤트에 참여하시고 푸짐한 선물 받아가세요!</span>
                    <span class="txt">프로모션과 세미나 등 다양한 이벤트에 지금 참여해보세요. 메가존클라우드여서 제공할 수 있는 유익한 정보와 특별 혜택 모두 놓치지 마세요.</span>
                  </div>
                </a>
              </div>
              <div class="part report">
                <h4 data-aos="fade-up">언론보도</h4>
                <a class="detail" data-aos="flip-up" data-aos-duration="600" href="#report" @click="goPath('/media','#report')">
                  <div class="cardImgBox">
                    <span class="cardImg card_02"></span>
                  </div>
                  <div class="txtWrap">
                    <span class="tit">언론에서도 주목한 메가존클라우드의 새로운 소식을 전해드립니다.</span>
                    <span class="txt">kt cloud와 관련된 메가존클라우드 소식을 모두 모아두었습니다. 이제 따로 검색할 필요 없이 여기에서 관련 기사를 편하게 읽어보세요.</span>
                  </div>
                </a>
              </div>
              <div class="part video">
                <h4 data-aos="fade-up">홍보영상</h4>
                <a class="detail" data-aos="flip-up" data-aos-duration="900" href="#video" @click="goPath('/media','#video')">
                  <div class="cardImgBox">
                    <span class="cardImg card_03"></span>
                  </div>
                  <div class="txtWrap">
                    <span class="tit">kt cloud와 함께 하는 메가존클라우드의 모습을 영상으로 담았습니다.</span>
                    <span class="txt">여러 행사의 현장과 메가존클라우드 내 kt cloud 전문가의 교육 등 영상을 보실 수 있습니다. 영상을 통해 생생한 그 현장과 열기를 느껴보세요.</span>
                  </div>
                </a>
              </div>
              <div class="part video">
                <h4 data-aos="fade-up">기술 블로그</h4>
                <a class="detail" data-aos="flip-up" data-aos-duration="900" href="https://www.notion.so/hybridai/afabe3ae5d1a4e919759953a0e9ce5ba?v=500e0e7dea4e430eb505eed798ecb2dd&pvs=4" target="_blank">
                  <div class="cardImgBox">
                    <span class="cardImg card_04"></span>
                  </div>
                  <div class="txtWrap">
                    <span class="tit">메가존클라우드의 시스템 엔지니어가 기술 정보를 쉽게 알려드립니다.</span>
                    <span class="txt">kt cloud는 처음이라 헤매던 모든 분들! 기술블로그를 통해 어려웠던 기술 정보를 쉽게 이해하고 kt cloud 사용 방법을 빠르게 마스터해보세요.</span>
                  </div>
                </a>
              </div>

            </div>
          </div>
        </section>

        <section class="contact" style="display: none;">
          <div class="container">
            <div class="titArea">
              <h2 data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="300">문의하기</h2>
              <h5 data-aos="fade-up" data-aos-offset="400" data-aos-easing="ease-in-sine" data-aos-duration="300">메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
            </div>
            <div class="conArea" data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="400">
              <dl>
                <dt>회사명</dt>
                <dd><input type="text" id="name" name="name" required  minlength="1" maxlength="50" placeholder="회사명을 입력하세요"></dd>
              </dl>
              <dl>
                <dt>이름</dt>
                <dd><input type="text" id="name" name="name" required  minlength="1" maxlength="50" placeholder="이름을 입력하세요"></dd>
              </dl>
              <dl>
                <dt>직책/직급</dt>
                <dd><input type="text" id="name" name="name" required  minlength="1" maxlength="50" placeholder="직책/직급을 입력하세요"></dd>
              </dl>
              <dl>
                <dt>연락처</dt>
                <dd><input type="text" id="name" name="name" required  minlength="11" maxlength="11" placeholder="연락처를 입력하세요"></dd>
              </dl>
              <dl>
                <dt>이메일</dt>
                <dd><input type="text" id="name" name="name" required  minlength="1" maxlength="100" placeholder="이메일을 입력하세요"></dd>
              </dl>
              <dl>
                <dt>문의유형</dt>
                <dd class="selectWrap">
                  <span class="selectTit">유형을 선택하세요<i class="fa-solid fa-sort-down"></i></span>
                  <div class="select">
                    <ul>
                      <li>111</li>
                      <li>222</li>
                      <li>333</li>
                    </ul>
                  </div>
                </dd>
              </dl>
              <dl class="askFrom">
                <dt>문의내용</dt>
                <dd><textarea name="opinion" cols="20" rows="6" placeholder="문의내용을 입력하세요"></textarea></dd>
              </dl>
              <div class="agreementWrap">
                <h4>개인정보 수집/이용 동의 [필수]</h4>
                <div class="formTxt"><input type="checkbox"><span class="txt">(필수) 네, 위 내용을 확인하였으며 개인 정보 수집 및 활용에 동의합니다.</span></div>
                <h4>마케팅 활용 동의 [선택]</h4>
                <div class="formTxt"><input type="checkbox"><span class="txt">(선택) 네, 위 내용을 확인하였으며, 회사가 신규서비스에 대한 뉴스레터, 홍보 및 다양한 행사 안내를 위해 본인의 개인정보를 회사의 마케팅 목적으로 이용되는 것에 동의합니다.</span></div>
                <span class="chk_textbox">
                  메가존 클라우드(“회사”)는 아래 내용에 따라 귀하의 정보를 수집 및 활용합니다. 다음의 내용을 숙지하시고 동의하는 경우 체크 박스에 표시해 주세요.<br><br>
                  1. 개인정보 수집자 : 메가존클라우드㈜<br><br>
                  2. 수집 받는 개인 정보<br>
                  [필수] 회사명, 성함, 직책, 연락처, 이메일주소<br><br>
                  3. 수집/이용 목적<br>
                  - 상담 문의 처리, 이벤트 참여 확인 및 당첨자 안내<br>
                  <strong>- (마케팅 활용 동의 시) 관련 신규 상품 소개, 서비스 제공 및 이용, 행사 안내 (이메일 또는 전화, 문자 알림)</strong><br><br>
                  <strong>4. 보유 및 이용 기간 : 수집일로부터 3년(단, 고객 동의 철회 시 지체없이 파기)</strong><br><br>
                  ※ 개인정보 이용 철회 방법<br>
                  - 안내 문자 등의 동의 철회를 이용하는 방법 : 이메일 수신 거부 링크 클릭 또는 안내 문자 내 수신거부 연락처를 통한 수신 거부 의사 통보<br>
                  - 개인정보 처리 상담 부서<br>
                  - 부서명: 마케팅그룹<br>
                  - 연락처: 02-2108-9171,&nbsp;event@megazone.com<br><br>
                  <strong>※ 동의거부권 및 불이익</strong><br>
                  귀하는 동의를 거절할 수 있는 권리를 보유하며, 동의를 거절하는 경우 상기 이용 목적에 명시된 서비스가 제공되지 아니합니다.
                </span>
              </div>
              <div class="bottomBtnArea">
                <button class="btn">문의하기</button>
              </div>
            </div>
          </div>
        </section>
    </div>
</template>

<script>
  export default {
    data: () => ({
      model: 0,
      colors: [
        'primary',
        'secondary',
        'yellow darken-2',
        'red',
        'orange',
      ],
      isActive: this.$route.name
    }),
    methods: {
      gotoPage (selectedPage) {
        if(this.$route.path!==selectedPage)
          this.$router.push({
            name: selectedPage
          }).catch(()=>{})
        this.isActive= this.$route.name
      },
      goPath(selectedPath, hash){
      this.$router.push({
        path: selectedPath,
        hash: hash
      })
      if(hash!=undefined) this.path = selectedPath+hash
      else this.path = selectedPath
      },
    }
  }
</script>


