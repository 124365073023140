<template>
  <div class="contents contactPage privacyPolicyPage">
    <div class="topVisual contactBg">
      <div class="container">
        <h4>개인정보처리방침</h4>
        <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
      </div>
      <div class="location">
        <div class="container">
          <i class="fa-solid fa-house" @click="goMain()"></i><i class="fa-solid fa-chevron-right"></i>
          <a href="../contact"><span class="txt">개인정보처리방침</span></a>
        </div>
      </div>
    </div>

    <section class="privacy" id="">
      <div class="container">
        <div class="list">
          <ul>
            <li><a href="#privacy01">제1조(개인정보의 처리목적)</a></li>
            <li><a href="#privacy02">제2조(수집하는 개인정보 항목)</a></li>
            <li><a href="#privacy03">제3조(개인정보의 처리 및 보유기간)</a></li>
            <li><a href="#privacy04">제4조(개인정보의 위탁)</a></li>
            <li><a href="#privacy05">제5조(개인정보의 파기)</a></li>
            <li><a href="#privacy06">제6조(정보주체의 권리·의무 및 행사방법)</a></li>
            <li><a href="#privacy07">제7조(개인정보의 안전성 확보조치)</a></li>
            <li><a href="#privacy08">제8조(개인정보 자동수집 장치의 설치·운영 및 거부에 관한 사항)</a></li>
            <li><a href="#privacy09">제9조(개인정보 보호책임자 및 담당부서)</a></li>
            <li><a href="#privacy10">제10조(개인정보 권리보장)</a></li>
            <li><a href="#privacy11">제11조(권익침해 구제방법)</a></li>
            <li><a href="#privacy12">제12조(링크 사이트에 대한 책임)</a></li>
            <li><a href="#privacy13">제13조(개인정보 처리방침 변경)</a></li>
          </ul>
          <span class="txt">메가존클라우드(주)(이하 ‘회사’)는 회사의 상담 및 신청 서비스(메가존클라우드 서비스)에 대해 이용자의 ‘동의를 기반으로 개인정보를 수집⋅이용 및 제공’하고 있으며, ‘이용자의 권리(개인정보 자기결정권)를 적극적으로 보장’합니다. 회사는 정보통신서비스제공자가 준수하여야 하는 대한민국의 관계 법령 및 개인정보보호 규정, 가이드라인을 준수하고 있습니다.</span>
        </div>

        <div class="privacyCon">
          <article id="privacy01">
            <h2>제1조(개인정보의 처리목적)</h2>
            <h5>회사는 다음의 목적으로 이용자의 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 것입니다.</h5>
            <span class="tit">1. 개인정보의 처리목적</span>
            <table class="privacytable">
              <colgroup>
                <col width="40%">
                <col width="60%">
              </colgroup>
              <thead>
                <tr>
                  <th width="40%">구분</th>
                  <th width="60%">처리목적</th>
                </tr>
              </thead>
              <tbody class="pageBody">
                <tr>
                  <td>서비스 상담신청</td>
                  <td>서비스 상담 업무 처리(kt cloud 등)</td>
                </tr>
                <tr>
                  <td>프로모션/세미나</td>
                  <td>프로모션/세미나 등의 이벤트 참석자 확인 및 안내, 설문조사</td>
                </tr>
                <tr>
                  <td>마케팅 및 홍보</td>
                  <td>뉴스레터 전송, 고객 맞춤 마케팅 및 홍보</td>
                </tr>
              </tbody>
            </table>
          </article>
          <article id="privacy02">
            <h2>제2조 (수집하는 개인정보 항목)</h2>
            <h5>회사는 다음의 개인정보를 수집하여 처리하고 있습니다. 이용자의 개인정보를 수집하는 경우에는 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 구하도록 하겠습니다.</h5>
            <span class="tit">1. 메가존클라우드 홍보홈페이지(mzktcloud.com)를 통해 수집하는 개인정보 항목</span>
            <table class="privacytable">
              <colgroup>
                <col width="30%">
                <col width="40%">
                <col width="30%">
              </colgroup>
              <thead>
                <tr>
                  <th width="30%">구분</th>
                  <th width="40%">수집항목</th>
                  <th width="30%">수집시점</th>
                </tr>
              </thead>
              <tbody class="pageBody">
                <tr>
                  <td>서비스 상담신청</td>
                  <td class="t_a_l">[필수] 회사명, 이름, 직책/직급, 연락처, 이메일, 문의유형<br>[선택] 문의내용</td>
                  <td>상담 신청 시</td>
                </tr>
                <tr>
                  <td>프로모션/세미나</td>
                  <td class="t_a_l">[필수] 회사명, 이름, 직책/직급, 연락처, 이메일, 문의유형<br>[선택] 문의내용</td>
                  <td>프로모션/세미나 등 이벤트 참가 신청 시</td>
                </tr>
              </tbody>
            </table>
            <span class="tit">2. 서비스 이용 중 자동으로 수집되는 정보(자동수집 장치를 통한 수집)</span>
            <table class="privacytable">
              <colgroup>
                <col width="30%">
                <col width="40%">
                <col width="30%">
              </colgroup>
              <thead>
                <tr>
                  <th width="30%">구분</th>
                  <th width="40%">수집항목</th>
                  <th width="30%">수집시점</th>
                </tr>
              </thead>
              <tbody class="pageBody">
                <tr>
                  <td>자동 생성 정보</td>
                  <td>접속 IP정보, 쿠키, 방문 기록, 서비스 이용 기록 등</td>
                  <td>서비스 이용 중 자동 생성</td>
                </tr>
              </tbody>
            </table>
          </article>
          <article id="privacy03">
            <h2>제3조(개인정보의 처리 및 보유기간)</h2>
            <h5>회사는 법령에 따른 개인정보 보유⋅이용기간 또는 정보주체(이용자)로부터 개인정보를 수집 시에 동의받은 개인정보 보유⋅이용기간 내에서 개인정보를 처리 및 보유합니다.</h5>
            <span class="tit">1. 서비스 제공을 위한 개인정보 보유 및 이용기간</span>
            <table class="privacytable">
              <colgroup>
                <col width="30%">
                <col width="40%">
                <col width="30%">
              </colgroup>
              <thead>
                <tr>
                  <th width="30%">구분</th>
                  <th width="40%">보유 사유</th>
                  <th width="30%">이용기간(보유기간)</th>
                </tr>
              </thead>
              <tbody class="pageBody">
                <tr>
                  <td>서비스 상담신청</td>
                  <td class="t_a_l">서비스 상담 업무 처리 목적 (kt cloud 등)</td>
                  <td>상담처리 후 3년</td>
                </tr>
                <tr>
                  <td>프로모션/세미나</td>
                  <td class="t_a_l">프로모션/세미나 등의 이벤트 참석자 확인 및 안내, 설문조사</td>
                  <td>행사종료 후 3년</td>
                </tr>
                <tr>
                  <td>마케팅 및 홍보</td>
                  <td class="t_a_l">뉴스레터 전송, 고객 맞춤 마케팅 및 홍보</td>
                  <td>3년</td>
                </tr>
              </tbody>
            </table>
            <span class="noti">※ 관계 법령의 규정에 의해 보존의 필요가 있는 경우는 분리보관하여 법령에 의한 기간까지 이용 및 보관합니다.</span>
            <span class="tit">2. 재화 또는 서비스 제공에 따른 법률에 의한 보존</span>
            <table class="privacytable">
              <colgroup>
                <col width="30%">
                <col width="40%">
                <col width="30%">
              </colgroup>
              <thead>
                <tr>
                  <th width="30%">구분</th>
                  <th width="40%">관련법률</th>
                  <th width="30%">이용기간(보유기간)</th>
                </tr>
              </thead>
              <tbody class="pageBody">
                <tr>
                  <td>계약 또는 청약철회 등에 관한 기록</td>
                  <td rowspan="4">전자상거래 등에서의 소비자 보호에 관한 법률</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                  <td>3년</td>
                </tr>
                <tr>
                  <td>표시 광고에 관한 기록</td>
                  <td>6개월</td>
                </tr>
                <tr>
                  <td>본인확인에 관한 기록</td>
                  <td>정보통신망 이용촉진 및 정보보호 등에 관한 법률</td>
                  <td>6개월</td>
                </tr>
                <tr>
                  <td>방문에 관한 기록</td>
                  <td>통신비밀보호법</td>
                  <td>3개월</td>
                </tr>
              </tbody>
            </table>
          </article>
          <article id="privacy04">
            <h2>제4조(개인정보의 위탁)</h2>
            <h5>회사는 원활한 서비스 제공, 이용자의 상담 처리를 위해 이용자의 개인정보를 국외로 이전하고 있으며, 보안프로토콜(SSL) 처리된 인터넷 망을 이용하여 전송되고 있습니다.</h5>
            <span class="tit">1. 개인정보 국내 위탁업무 내용</span>
            <table class="privacytable">
              <colgroup>
                <col width="30%">
                <col width="30%">
                <col width="40%">
              </colgroup>
              <thead>
                <tr>
                  <th width="30%">수탁업체</th>
                  <th width="30%">제공목적(위탁업무 내용)</th>
                  <th width="40%">보유 및 이용기간</th>
                </tr>
              </thead>
              <tbody class="pageBody">
                <tr>
                  <td>Amazon Web Services, Inc.</td>
                  <td>데이터 보관 및 인프라 관리</td>
                  <td>이용목적 달성 또는 위탁계약 종료 시까지</td>
                </tr>
              </tbody>
            </table>
            <span class="noti">※ 관계 법령의 규정에 의해 보존의 필요가 있는 경우는 해당 기간까지 이용 및 보관합니다.</span>
            <span class="tit">2. 개인정보 국외 위탁업무 내용</span>
            <table class="privacytable">
              <colgroup>
                <col width="10%">
                <col width="15%">
                <col width="15%">
                <col width="20%">
                <col width="10%">
                <col width="15%">
                <col width="15%">
              </colgroup>
              <thead>
                <tr>
                  <th width="10%">이전업체</th>
                  <th width="15%">연락처</th>
                  <th width="15%">이전목적</th>
                  <th width="20%">이전되는 항목</th>
                  <th width="10%">이전되는 국가</th>
                  <th width="15%">이전일시 및 방법</th>
                  <th width="15%">개인정보 이용기간</th>
                </tr>
              </thead>
              <tbody class="pageBody">
                <tr>
                  <td>Salesforce, Inc.</td>
                  <td>Salesforce Data Protection Officer<br>privacy@salesforce.com</td>
                  <td>상담정보 및 고객 관리</td>
                  <td>제2조(수집하는 개인정보 항목)에 기재된 개인정보: 회사, 이름, 직급, 부서, 연락처, 이메일, 상담신청 문의경로, 사업장 위치(상세주소), 회사 전화번호, 회사주소, 문의 내용</td>
                  <td>일본</td>
                  <td>상담 문의 또는 고객관리 시 네트워크를 통한 전송</td>
                  <td>이용목적 달성 또는 위탁 계약 종료 시까지</td>
                </tr>
              </tbody>
            </table>
            <span class="noti">※ 관계 법령의 규정에 의해 보존의 필요가 있는 경우는 해당 기간까지 이용 및 보관합니다.</span>
            <span class="tit">3. 회사는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.</span>
            <span class="tit">4. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</span>
          </article>
          <article id="privacy05">
            <h2>제5조(개인정보의 파기)</h2>
            <h5>회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 정보를 파기합니다.</h5>
            <span class="tit">1. 정보 주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</span>
            <span class="tit">2. 개인정보 파기의 절차 및 방법은 다음과 같습니다.</span>
            <span class="txt02">① 파기절차 : 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</span>
            <span class="txt02">② 파기방법 : 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기, 종이 문서에 기록·저장 된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.<br>
            제7조(정보주체의 권리·의무 및 행사방법)<br>
            가. 회사는 개인정보 보유 기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때는 지체없이 해당 개인정보를 파기합니다.
            </span>
          </article>
          <article id="privacy06">
            <h2>제6조(정보주체의 권리·의무 및 행사방법)</h2>
            <span class="tit">1. 정보주체(이용자)는 회사에 대해 언제든지 다음 각 호의 개인정보보호 관련 권리를 행사할 수 있습니다.</span>
            <span class="txt02">① 개인정보 열람 요구</span>
            <span class="txt02">② 오류 등이 있을 경우 정정 요구</span>
            <span class="txt02">③ 삭제요구</span>
            <span class="txt02">④ 처리정지 요구</span>
            <span class="tit">2. 제1항에 따른 권리 행사는 회사에 대해 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다.</span>
            <span class="tit">3. 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.</span>
            <span class="tit">4. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 처리 방법에 관한 고시(제2020-7호) 별지 제11호 서식에 따른 위임장을 제출하여야 합니다.</span>
            <span class="tit">5. 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</span>
            <span class="tit">6. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</span>
            <span class="tit">7. 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</span>
          </article>
          <article id="privacy07">
            <h2>제7조(개인정보의 안전성 확보조치)</h2>
            <h5>회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</h5>
            <span class="tit">1. 관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등</span>
            <span class="tit">2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접속기록의 보관 및 위조᛫변조 방지를 위한 조치, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치 등</span>
            <span class="tit">3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제 등</span>
          </article>
          <article id="privacy08">
            <h2>제8조(개인정보 자동수집 장치의 설치·운영 및 거부에 관한 사항)</h2>
            <span class="tit">1. 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</span>
            <span class="tit">2. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</span>
            <span class="txt02">① 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</span>
            <span class="txt02">② 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</span>
            <span class="txt02">③ 쿠키 설정 거부 방법<br>
            ⅰ) Internet Explorer: 도구 메뉴 선택 > 인터넷 옵션 선택 > 개인정보 탭 클릭 > 고급 개인정보 설정 > 쿠키 수준 설정<br>
            ⅱ) Chrome: 설정 메뉴 선택 > 고급 설정 표시 선택 > 개인정보 및 보안 > 콘텐츠 설정 선택 > 쿠키 수준 설정<br>
            ⅲ) Safari: 환경설정 메뉴 선택 > 개인정보 탭 선택 > 쿠키 및 웹 사이트 데이터 수준 설정<br>
            ⅳ) Microsoft Edge: 설정 메뉴 선택 > 사이트 사용권한 탭 선택 > 쿠키 및 사이트 데이터 설정</span>
          </article>
          <article id="privacy09">
            <h2>제9조(개인정보 보호책임자 및 담당부서)</h2>
            <h5>회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</h5>
            <span class="tit">1. 개인정보 보호책임자 및 담당부서</span>
            <table class="privacytable">
              <colgroup>
                <col width="50%">
                <col width="50%">
              </colgroup>
              <thead>
                <tr>
                  <th width="50%">개인정보 보호책임자</th>
                  <th width="50%">개인정보 보호 담당부서</th>
                </tr>
              </thead>
              <tbody class="pageBody">
                <tr>
                  <td>이름 : 이주완<br>직책 : 대표이사<br>전화번호 : 1644-2243</td>
                  <td>담당부서 : IRM<br>전화번호 : 1644-7378<br>이메일 : irm@mz.co.kr</td>
                </tr>
              </tbody>
            </table>
          </article>
          <article id="privacy10">
            <h2>제10조(개인정보 권리보장)</h2>
            <h5 class="m_b_30">정보주체는 개인정보 보호법 제35조, 제36조 및 제37조에 따른 개인정보의 열람, 정정·삭제, 처리정지 등을 아래의 부서에 할 수 있습니다.<br>회사는 정보주체의 권리 행사에 신속하게 처리되도록 노력하겠습니다.</h5>
            <table class="privacytable">
              <colgroup>
                <col width="100%">
              </colgroup>
              <thead>
                <tr>
                  <th width="100%">접수⋅처리 부서</th>
                </tr>
              </thead>
              <tbody class="pageBody">
                <tr>
                  <td>이름 : 마케팅팀<br>연락처 : 02-2108-9177<br>이메일 : mz_marketing@megazone.com</td>
                </tr>
              </tbody>
            </table>
          </article>
          <article id="privacy11">
            <h2>제11조(권익침해 구제방법)</h2>
            <h5 class="m_b_30">정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.<br>아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.</h5>
            <table class="privacytable privacytable2">
              <colgroup>
                <col width="30%">
                <col width="15%">
                <col width="55%">
              </colgroup>
              <tbody class="pageBody">
                <tr>
                  <td rowspan="4" class="titTd">개인정보 침해신고센터(한국인터넷진흥원 운영)</td>
                  <td>소관업무</td>
                  <td class="t_a_l">개인정보 침해사실 신고, 상담 신청</td>
                </tr>
                <tr>
                  <td>홈페이지</td>
                  <td class="t_a_l">privacy.kisa.or.kr</td>
                </tr>
                <tr>
                  <td>전화</td>
                  <td class="t_a_l">(국번 없이) 118</td>
                </tr>
                <tr>
                  <td>주소</td>
                  <td class="t_a_l">(58324) 전남 나주시 진흥길 9(빛가람동 301-2) 개인정보침해신고센터</td>
                </tr>
                <tr>
                  <td rowspan="4" class="titTd">개인정보 분쟁조정위원회(개인정보보호위원회 운영)</td>
                  <td>소관업무</td>
                  <td class="t_a_l">개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</td>
                </tr>
                <tr>
                  <td>홈페이지</td>
                  <td class="t_a_l">www.kopico.go.kr</td>
                </tr>
                <tr>
                  <td>전화</td>
                  <td class="t_a_l">(국번 없이) 1833-6972</td>
                </tr>
                <tr>
                  <td>주소</td>
                  <td class="t_a_l">(03171)서울특별시 종로구 세종대로 209 정부서울청사 12층</td>
                </tr>
                <tr>
                  <td rowspan="2" class="titTd">대검찰청 사이버수사과</td>
                  <td>홈페이지</td>
                  <td class="t_a_l">www.spo.go.kr</td>
                </tr>
                <tr>
                  <td>전화</td>
                  <td class="t_a_l">(국번 없이) 1301</td>
                </tr>
                <tr>
                  <td rowspan="2" class="titTd">경찰청 사이버수사국</td>
                  <td>홈페이지</td>
                  <td class="t_a_l">ecrm.cyber.go.kr</td>
                </tr>
                <tr>
                  <td>전화</td>
                  <td class="t_a_l">(국번 없이) 182</td>
                </tr>
              </tbody>
            </table>
          </article>
          <article id="privacy12">
            <h2>제12조(링크 사이트에 대한 책임)</h2>
            <h5>회사는 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성, 진실성, 적법성에 대해 책임질 수 없으며 보증할 수 없습니다. 회사가 포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은 회사와 무관하므로 새로 방문한 사이트의 정책을 확인하시기 바랍니다.</h5>
          </article>
          <article id="privacy13">
            <h2>제13조(개인정보 처리방침 변경)</h2>
            <h5>현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 고지할 것입니다. 다만, 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.<br><br>
            이 개인정보 처리방침은 2023.06.16 부터 적용됩니다.</h5>
          </article>
        </div>
      </div>
    </section>

  </div>
</template>


<script>
export default {
  data() {
    return {
      page: 1,
      note_id: null,
    }
  },
  methods: {
    goMain() {
      this.$router.push({ name: 'MainPage' })
    },
    selectPageNumber(page) {
      this.page = page
    }
  }
}
</script>



