<template>
  <div class="contents mediaPage videoPage">
    <div class="topVisual">
      <div class="container">
        <h4>미디어</h4>
        <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
      </div>
      <div class="location">
        <div class="container">
          <i class="fa-solid fa-house" @click="goMain()"></i><i class="fa-solid fa-chevron-right"></i>
          <a href="../media"><span class="txt">미디어</span></a><i class="fa-solid fa-chevron-right"></i>
            <a href="../media#video"><span class="txt">홍보영상</span></a>
        </div>
      </div>
    </div>
      
    <section class="video" id="video">
      <div class="container">
        <div class="titArea">
          <h2>홍보영상</h2>
          <h5>메가존클라우드는 클라우드 전문 컨설팅을 통해 고객사에 최적의 kt cloud를 도입할 수 있도록 지원합니다.</h5>
        </div>
        <div class="conArea">
          <!-- contents 상세 start -->
          <div class="detail">
            <div class="topWrap"><h4 class="tit">[대한민국 4차 산업혁명 페스티벌 2022] 정보시스템 대상 클라우드 전환 우수사례_kt cloud Sales 이준희 팀장</h4><span class="name">관리자</span><span class="date">2022.12.22</span></div>
            <div class="conWrap">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/gVgJBCAVTM8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <!-- contents 상세 end -->
          <div class="bottomWrap">
            <a href="../media#video"><button class="btnList"><i class="fa-solid fa-chevron-left"></i>목록으로</button></a>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>


<script>
export default {
  methods: {
    goMain(){
      this.$router.push({name: 'MainPage'})
    },
  }
}
</script>


